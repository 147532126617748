// @ts-ignore
import { getRegisterField } from "@/api/common_api";
// @ts-ignore
import { ref } from "vue";
export default function useRegDialog() {
  const formInfo = ref(null);
  const formVisible = ref(null);
  const getRegListCom = async () => {
    try {
      /**
       * 注册灵活字段
       */
      const { data } = await getRegisterField();
      if (data.length > 0) {
        formInfo.value = data;
        formVisible.value = true;
      }
    } catch (error) {}
  };
  return {
    getRegListCom,
    formInfo,
    formVisible,
  };
}
