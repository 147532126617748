<template>
  <div class="flex-center">
    <div class="label">
      {{ item.title }}<span v-if="parseInt(item.required) === 1">*</span>：
    </div>
    <el-input
      v-if="item"
      type="text"
      class="text-field"
      :disabled="edit"
      v-model="fieldValue"
      :placeholder="item.prompt"
      @change="validText"
    />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { Field } from "./df";
import useValidator from "../../hooks/form/useValidator";

export default defineComponent({
  name: "text-field",
  props: {
    item: {
      type: Object as PropType<Field>,
      default: () => {
        return null;
      },
    },
    currentIndex: {
      type: Number,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const fieldValue = ref("");
    const updateValue = () => {
      const { item } = props;
      item.value = fieldValue.value;
      context.emit("updateField", {
        index: props.currentIndex,
        item,
      });
    };
    onMounted(() => {
      fieldValue.value = props.item.value;
    });
    const { validMaps } = useValidator();
    const validText = (e) => {
      const { name, title } = props.item;
      const method = validMaps.get(name);
      if (!method) {
        props.item.error = !!e ? "" : "请填写" + title;
      } else {
        props.item.error = method(e);
      }
      updateValue();
    };
    return {
      fieldValue,
      validText,
      updateValue,
    };
  },
});
</script>

<style lang="css" scoped>
::v-deep(.el-input__inner) {
  height: 51px;
  line-height: 51px;
}
</style>

<style lang="less" scoped>
.flex-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-field {
  width: 364px;
  height: 51px;
  line-height: 51px;
  box-sizing: border-box;
  color: #111111;
}

.label {
  font-size: 14px;
  width: 100px;
  font-weight: 400;
  color: #111111;
  text-align: right;
  span {
    color: #f56c6c;
  }
}

.disabled {
  color: #999 !important;
}
</style>
