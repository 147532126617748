<template>
  <div class="reset-form">
    <div class="title">
      <h3>忘记密码</h3>
    </div>
    <el-form :model="resetForm" label-width="110px">
      <el-form-item label="填写手机号" prop="phone">
        <el-input
          v-model="resetForm.phone"
          placeholder="请填写手机号"
          style="width: 100%; height: 50px"
          maxlength="11"
        ></el-input>
      </el-form-item>
      <el-form-item label="填写验证码" prop="code">
        <el-input
          v-model="resetForm.verify_code"
          placeholder="请填写验证码"
          maxlength="4"
          style="width: 55%"
        ></el-input>
        <span
          class="obtain-code"
          @click="sendCode()"
          :class="[disabled ? 'codeDisabled' : '']"
          >{{
            codeTextShow === 0
              ? codeText.getCode
              : codeTextShow === 1
              ? codeText.countDown + "(" + count + "s)"
              : codeText.countDown
          }}</span
        >
      </el-form-item>
      <el-form-item label="设置新密码" prop="password">
        <el-input
          type="password"
          v-model="resetForm.password"
          placeholder="请设置新密码"
          style="width: 100%"
        ></el-input>
        <p style="color: #999999">6-16位数字和字母组合</p>
      </el-form-item>
      <el-form-item label="确认新密码" prop="againPassword">
        <el-input
          type="password"
          v-model="resetForm.againPassword"
          placeholder="请输入确认密码"
          style="width: 100%"
        ></el-input>
        <p style="color: #999999">6-16位数字和字母组合</p>
      </el-form-item>
      <div
        class="submit"
        :class="{
          'submit-btn':
            resetForm.phone &&
            resetForm.verify_code &&
            resetForm.password &&
            resetForm.againPassword,
        }"
      >
        <el-button type="primary" @click="submitForm()">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import { getVerifyCode, getUserPassword } from "@/api/auth_api";
import VueEvent from "../../utils/global_event";
export default {
  name: "reset-pwd",
  setup() {
    const resetForm = ref({
      phone: null,
      verify_code: null,
      password: null,
      againPassword: null,
    });
    const codeText = ref({
      getCode: "获取验证码",
      countDown: "重新发送",
    });
    const codeTextShow = ref(0);
    const count = ref(59);
    const hash = ref(null);
    const disabled = ref(false);
    // 发送验证码并进行判断
    const sendCode = async () => {
      try {
        if (
          !resetForm.value.phone ||
          !/^1[3-9]\d{9}$/.test(resetForm.value.phone)
        ) {
          ElMessage.error("请先填写正确的手机号");
          return;
        }
        const { data, msg } = await getVerifyCode({
          phone: resetForm.value.phone,
        });
        ElMessage.success(msg);
        hash.value = data.hash;
        codeTextShow.value = 1;
        disabled.value = true;
        let interval = setInterval(() => {
          --count.value;
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
          count.value = 59;
          codeTextShow.value = 2;
          disabled.value = false;
        }, 60000);
      } catch (error) {
        ElMessage.error(error);
      }
    };
    // 修改密码流程
    const submitForm = async () => {
      if (
        !resetForm.value.phone ||
        !/^1[3-9]\d{9}$/.test(resetForm.value.phone)
      ) {
        ElMessage.error("请先填写正确的手机号");
        return;
      }
      if (!resetForm.value.verify_code) {
        ElMessage.error("请先输入验证码");
        return;
      }
      if (
        !resetForm.value.password ||
        !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/g.test(
          resetForm.value.password
        )
      ) {
        ElMessage.error("请填写6-16位数字和字母组合的密码");
        return;
      }
      if (resetForm.value.password !== resetForm.value.againPassword) {
        ElMessage.error("两次密码输入不一致");
        return;
      }
      try {
        let { msg } = await getUserPassword(resetForm.value);
        ElMessage.success(msg);
        VueEvent.emit("backLogin");
      } catch (error) {
        ElMessage.error(error);
      }
    };
    return {
      resetForm,
      codeTextShow,
      count,
      disabled,
      submitForm,
      sendCode,
      codeText,
    };
  },
};
</script>

<style lang="less" scoped>
.reset-form {
  margin: 80px 40px;
  position: relative;
  top: -30px;
  box-sizing: border-box;
  .title {
    width: 80%;
    margin: 0 auto 30px;
    padding: 20px;
    border-bottom: 1px solid #dddddd;
    text-align: center;
  }
  ::v-deep(.el-form-item__content) {
    position: relative;
  }
  ::v-deep(.el-input__inner) {
    height: 50px;
  }
  ::v-deep(.el-form-item__label) {
    height: 50px;
    line-height: 50px;
  }
  .obtain-code {
    width: 115px;
    height: 50px;
    background: #0398FE;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    line-height: 50px;
    color: #fff;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  .codeDisabled {
    background: #d5d5d5 !important;
  }
  .submit {
    text-align: center;
    margin: 20px 40px 40px;
    .el-button {
      border-radius: 22px;
      text-align: center;
      font-size: 20px;
      letter-spacing: 3px;
      width: 200px;
      height: 44px;
      background: #dddddd;
      border: none;
    }
  }
  .submit-btn {
    .el-button {
      background: #0398FE;
    }
  }
  ::v-deep(.el-form-item__label:before) {
    content: "*";
    color: #05C064;
    margin-right: 2px;
  }
}
</style>
<style>
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
  content: "";
}
</style>
