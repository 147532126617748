import { isEqual } from 'lodash'
import {ElMessage} from "element-plus";
const reqTimeOut = 500 // 请求防抖时间
let reqList = [] // 请求接口列表
const whiteUrlList = [
  { url: '/doctor/lists' }, // 请求医生列表接口
  { url: '/meeting/lists' }, // 请求会议列表接口
  { url: '/search/fulltext' }, // 搜索接口
  { url: '/search/meeting' }, // 搜索会议接口
  { url: '/search/course' }, // 搜索课程接口
  { url: '/document/categories' },
  { url: '/document/index' },
  { url: '/search/video' },
  { url: '/news/index' },
  { url: '/notifications/unread' },
  { url: '/user/profile' },
  { url: '/meeting/live/view' },
  { url: '/task/progress' },
  { url: '/task/video' },
  { url: '/video/show' },
  { url: '/learn/${params}' },
  { url: '/meeting/video/view' },
  { url: '/point/balance' },
  { url: '/user/sign' }, // 签到接口, 一个get 一个post, 正常来说是不会重名的
  { url: '/courses/medias' },
  { url: '/courses/view' },
  { url: '/doctor/follow/${params}' },
  { url: '/user/auth' },
  { url: '/meeting/forms' },
  { url: '/favorites' },
  { url: '/meeting/buy' },
  { url: '/advert/index/${params}' },
  { url: '/common/areas/tree' },
  { url: '/notifications' }, // 消息列表
  { url: '/common/department/tree' },
  { url: '/courses' },
  { url: '/task/index' },
  { url: '/common/department/lists' },
  { url: '/point/notice' },
  { url: 'user/get-depart' },
  { url: '/notifications/mark-all-read' },
  { url: '/point/settings' },
  { url: '/task/lists/${params}' },
  { url: '/meeting/index' },
  { url: '/money/orders' },
  { url: '/user/inviteInfo' },
  { url: '/shop/orders' },
  { url: '/common/settings' },
  { url: '/user/address' },
  { url: '/meeting/reward' }, // 打赏二维码
] // 不加防抖的接口白名单

/**
 * 阻止重复请求
 * @param {string} url - 当前请求地址
 * @param {function} params - 请求携带参数
 * @param {function} cancel - 请求中断函数
 * @param {string} errorMessage - 请求中断时需要显示的错误信息
 */
const stopRepeatRequest = function(url, params = '', cancel, errorMessage) {
  const errorMsg = errorMessage || ''
  // todo 如果上个请求未完成并且不在白名单中，相同的请求不会进行 (判断参数不相等则视为不是同一个请求)
  const isRepeatRequest = reqList.some(item => item.url === url && isEqual(item.params, params))
  const isNotInWhiteList = !whiteUrlList.some(item =>
      {
        // 白名单的地址没参数, 则正常比较, 否则截取地址的前半部分进行比较
        const index = item.url.indexOf('/${params}')
        if (index === -1) {
          return item.url === url.split('?')[0] // 正常来说url是没有任何参数的, 但是项目中存在直接把参数写在request请求的urr上的情况, 比如 /meeting?per_page=12
        } else {
          return item.url.slice(0, index) === url.slice(0, index)
        }
      }
  )
  if (isRepeatRequest && isNotInWhiteList) {
    cancel(errorMsg)
    console.log(url, '重复请求地址')
    return
  }
  reqList.push({ url, params })
}
/**
 * 允许某个请求可以继续进行
 * @param {string} url 请求地址
 */
const allowRequest = function(url) {
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i].url === url) {
      reqList.splice(i, 1)
      break
    }
  }
}

export { reqTimeOut, stopRepeatRequest, allowRequest }
