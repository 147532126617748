import Layout from "@components/layout/Layout.vue";
// 病例库页面
export default {
    path: "/regulations",
    component: Layout,
    meta: {
        transition: "slide",
    },
    children: [
        {
            path: "index",
            component: () => import("@pages/regulations/index.vue"),
        },
        {
            path: "detail",
            component: () => import("@pages/regulations/detail.vue"),
        },
    ],
};
