<template>
  <div class="flex-center">
    <div class="label">
      {{ item.title }}<span v-if="parseInt(item.required) === 1">*</span>：
    </div>
    <el-autocomplete
      class="text-field"
      :disabled="edit"
      v-model="fieldValue"
      :fetch-suggestions="querySearchAsync"
      :placeholder="item.prompt"
      @select="updateValue"
    />
  </div>
</template>
<script lang="ts">
import { getHospitalSearch, getCompanySearch } from "../../api/search_api";
import { defineComponent, onMounted, PropType, ref } from "vue";
import { Field } from "./df";
import useArrayUtils from "../../hooks/common/useArrayUtils";

export default defineComponent({
  name: "auto-complete",
  props: {
    item: {
      type: Object as PropType<Field>,
      default: () => {
        return null;
      },
    },
    currentIndex: {
      type: Number,
      default: null,
    },
    userType: {
      type: Number,
      default: 0,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateField"],
  setup(props, context) {
    const fieldValue = ref("");
    const querySearchAsync = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      if (!queryString) cb([]);
      if (props.userType === 1) {
        const { data } = await getHospitalSearch({
          word: queryString,
        });
        cb(
          data.map((hospital) => {
            return {
              value: hospital.name,
              ...hospital,
            };
          })
        );
      } else {
        const { data } = await getCompanySearch({
          word: queryString,
        });
        cb(
          data.map((company) => {
            return {
              value: company.company_name,
              ...company,
            };
          })
        );
      }
    };
    const { isValidArray } = useArrayUtils();
    const updateValue = (result) => {
      const { item } = props;
      const { value, id } = result;
      item.value = value;
      item.ids = [id];
      fieldValue.value = value;
      item.error =
        item.value && isValidArray(item.ids) ? "" : "请输入关键字从下拉框选择";
      context.emit("updateField", {
        index: props.currentIndex,
        item,
      });
    };

    onMounted(() => {
      const { value } = props.item;
      fieldValue.value = value;
    });

    return {
      fieldValue,
      querySearchAsync,
      updateValue,
    };
  },
});
</script>

<style lang="less" scoped>
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep(.el-input__inner) {
    width: 364px;
    height: 51px;
    line-height: 51px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    //background: #ffffff;
    border: 1px solid #d8e2f0;
    font-size: 14px;
  }
}

.result {
  border: 1px solid #d8e2f0;
  height: 400px;
  width: 600px;
  overflow: scroll;

  .result-item {
    height: 35px;
    line-height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #d8e2f0;
    cursor: pointer;
  }
}

.label {
  font-size: 14px;
  width: 100px;
  font-weight: 400;
  color: #111111;
  text-align: right;
  span {
    color: #f56c6c;
  }
}
</style>
