<template>
  <div class="wrapper">
    <div class="box" v-if="!isMobile">
      <div class="title">提示</div>
      <div class="content">您正使用微信内置浏览器，为了您能正常观看直播、完成积分任务，请点击上方工具栏 <strong style="color: red">使用默认浏览器打开</strong>
        按钮，在微信外打开，或点击上方<strong style="color: red">复制网址</strong>按钮，复制本页面地址到使用谷歌、360、火狐等浏览器进行访问！
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
export default {
  name: "un-support.vue",
  setup(){
    const flag = isMobile()
    const isMobile = ref(flag)
    return {isMobile}
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .box {
    width: 480px;
    height: 220px;
    border: 2px solid #333333;
    border-radius: 12px;
    padding: 10px;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: 22px;
      font-weight: 500;
    }

    .content {
      margin-top: 20px;
      text-indent: 34px;
    }
  }
}
</style>