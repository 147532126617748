import request from "../utils/request";

/**
 * 获取验证码,
 * param: phone 手机号
 */
export function getVerifyCode(params) {
  return request.post(`/oauth/code`, params);
}

/**
 * 密码登录
 * @param {
 *  phone,password
 * } params
 * @returns
 */
export function pwdLogin(params) {
  return request.post(`/oauth/token`, params);
}

/**
 * 验证码登录
 * @param {
 * phone,verify_code} params
 * @returns
 */
export function codeLogin(params) {
  return request.post(`/oauth/login`, params);
}

/**
 * 修改密码
 * @param {
 * phone,verify_code} params
 * @returns
 */
export function getUserPassword(params) {
  return request.post(`/oauth/forgot-pwd`, params);
}

/**
 * 验证手机号是否注册
 * @param {phone} params
 * @returns
 */
export function getUserExists(params) {
  return request.get(`/user/exists`, params);
}

/**
 *
 * @returns 微信认证，无效
 */
export function weChatAuth() {
  return request.get(`/wechat/check`, {});
}

/**
 * 微信code换openid
 * @param {*} params
 * @returns
 */
export function code2openId(params) {
  return request.get(`/wechat/openid`, params);
}

/**
 * 获取微信配置
 */
export function getWxConfig(params) {
  return request.post(`/wechat/config`, params);
}

/**
 * 获取某用户信息
 * @param pathVar
 * @returns
 */
export function getUserProfile(pathVar) {
  return request.get(`/user/${pathVar}`, {});
}

/**
 * 刷新socket token
 */
export function refreshSocketToken() {
  return request.get(`/user/socket-token`, {});
}

/**
 * 设置用户位置接口
 * params
 */
export function setLocation(params = {}) {
  return request.post(`/user/set-location`, params);
}
/**
 * 添加科室
 * params
 */
export function addDepartment(params = {}) {
  return request.post(`/user/set-depart`, params);
}
/**
 * 已选科室
 * @param {*} params
 */
export function getDepartmentLists() {
  return request.get(`user/get-depart`, {});
}
