<script>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import BasisHeader from "./components/common/basis-header.vue";
import BasisFooter from "./components/common/basis-footer.vue";
import UnSupport from "./components/common/un-support.vue";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import Layout from "@components/layout/Layout.vue";
import FloatIcon from "./components/common/float-icon.vue";
import { detectZoom } from "./utils/ZoomUtils";
import { ElNotification } from "element-plus";

export default {
  components: {
    FloatIcon,
    Layout,
    BasisFooter,
    BasisHeader,
    UnSupport,
  },
  setup() {
    const store = useStore();
    // when app mounted, load userInfo
    onMounted(() => {
      // load loginUserInfo
      store.dispatch("updateUserInfo");
      // load appSetting
      store.dispatch("fetchAppSetting");
      // load local token
      store.dispatch("fetchToken");
      // load socket token
      store.dispatch("fetchSocketToken");
    });
    const isWeChatBrowser = ref(false);
    isWeChatBrowser.value = isWeChat();
    // const checkRatio = () => {
    //   const ratio = detectZoom()
    //   if (ratio !== 100) {
    //     ElNotification.closeAll()
    //     const percent = ratio + '%'
    //     ElNotification.warning({
    //       title: '温馨提示',
    //       message: `您的浏览器缩放比例为${percent},可能会导致页面显示异常，请使用ctrl+0(MAC为Command+0)组合键来设置正确的比例`,
    //       duration: 0
    //     })
    //   }else{
    //     ElNotification.closeAll()
    //   }
    // }
    // window.onresize = () => {
    //   checkRatio()
    // }
    // checkRatio()
    return {
      isWeChatBrowser,
    };
  },
};
</script>
<template>
  <template v-if="!isWeChatBrowser">
    <!-- 头部 -->
    <basis-header />
    <!-- 路由 -->
    <div class="app-main-content">
      <Layout />
    </div>
    <!-- 尾部 -->
    <basis-footer class="app-basis-footer"/>
    <float-icon />
  </template>
  <template v-else>
    <UnSupport />
  </template>
</template>

<style>
:root {
  --jy-color-white: #ffffff;
  --jy-color-black: #111111;
  --jy-color-gray: #888888;
}

.el-message {
  z-index: 9999 !important;
}
::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 1px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.4);
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-width: 1200px;
  position: relative;
}

* {
  margin: 0;
  padding: 0;
}
.index-top-bg {
  background: url("@/assets/img/common/index-header-bg.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 352px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
body {
  line-height: initial !important;
}

.app-main-content {
  min-height: calc(100vh - 394px);
}

.app-basis-footer {
  position: absolute;
  bottom: 0;
}
</style>
