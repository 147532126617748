import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/assets/css/element.less";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import App from "./App.vue";
/**
 * vue3预览图片插件
 * 结合 v-html 解析富文本使用
 */
import vue3PreviewImage from "vue3-preview-image";
// 加载路由
import router from "./router/index";
// 加载vuex
import store from "./store/index";
import { create, NCarousel, NScrollbar, NCountdown } from "naive-ui";

const naive = create({
  components: [NCarousel, NScrollbar, NCountdown],
});
// 创建路由
const app = createApp(App);
app.use(vue3PreviewImage);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(naive);
app.use(router);
app.use(store);
app.mount("#app");
