<template>
  <div class="flex-center">
    <div class="label">
      {{ item.title }}<span v-if="parseInt(item.required) === 1">*</span>：
    </div>
    <el-upload
      class="upload-demo"
      v-model:file-list="fileList"
      :disabled="edit"
      ref="uploader"
      drag
      :action="uploadAddress"
      :multiple="false"
      :headers="{
        Authorization: token,
      }"
      :data="{
        dir: 'form',
      }"
      :on-success="updateValue"
      :limit="1"
      :on-remove="clearFile"
      :on-exceed="change"
    >
      <el-icon class="el-icon--upload">
        <upload-filled />
      </el-icon>
      <div class="el-upload__text">拖拽文件到这里或者 <em>点击上传</em></div>
    </el-upload>
  </div>
</template>
<script>
import { UploadFilled } from "@element-plus/icons";
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "image-field",
  components: {
    UploadFilled,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      },
    },
    currentIndex: {
      type: Number,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = useStore();
    const value = ref("");
    const uploadAddress = ref("");
    const fileList = ref([]);
    const token = computed(() => store.getters.getToken);
    onMounted(() => {
      uploadAddress.value =
        import.meta.env.VITE_APP_BASE_URL + "/uploader/image";
      if (props.item.value) {
        fileList.value.push({
          name: props.item.value.slice(0, 40) + "...",
          url: props.item.value,
        });
      }
    });
    const updateValue = (...callbackArgs) => {
      const response = callbackArgs[0];
      const { msg, errorCode, data } = response;
      // 如果上传失败
      if (errorCode !== 0) {
        ElMessage.error(msg);
        clearFile();
        return;
      }
      // 和h5 一样对展示url 对效果 不然会展示文件名
      fileList.value[0] = {
        name: data.url.slice(0, 40) + "...",
        url: data.url.slice(0, 40) + "...",
      };
      const { item, currentIndex } = props;
      item.value = data.url;
      item.error = !!item.value ? "" : "上传失败，请重新上传";
      context.emit("updateField", {
        index: currentIndex,
        item,
      });
    };

    const uploader = ref(null);
    const clearFile = async () => {
      await nextTick();
      uploader.value.clearFiles();
      const { item, currentIndex } = props;
      item.value = "";
      context.emit("updateField", {
        index: currentIndex,
        item,
      });
    };

    return {
      value,
      token,
      uploadAddress,
      uploader,
      updateValue,
      fileList,
      clearFile,
      change: (evt) => {
        ElMessage.warning("仅能上传一个文件，如需上传新文件，请移除当前文件");
      },
    };
  },
});
</script>
<style scoped>
::v-deep(.el-icon--upload) {
  font-size: 80px;
  line-height: 100px;
  margin-top: 30px;
}

.el-icon--upload {
  color: #999999;
}
</style>
<style lang="less" scoped>
.flex-center {
  width: 100%;
  display: flex;
  align-items: stretch;
}

.label {
  font-size: 14px;
  width: 100px;
  font-weight: 400;
  color: #111111;
  text-align: right;
  span {
    color: #f56c6c;
  }
}
</style>
