<template>
  <div class="flex-center">
    <div class="label">
      {{ item.title }}<span v-if="parseInt(item.required) === 1">*</span>：
    </div>
    <el-cascader
      ref="picker"
      :options="pickerData"
      @change="confirm"
      :disabled="edit"
      :props="{ value: valueField, label: labelField }"
      :placeholder="
        item.name === 'job_title' ? '请选择职业后再选择职称' : item.prompt
      "
      v-model="value"
    >
    </el-cascader>
  </div>
</template>
<script>
// 获取省市区及科室
import {
  getDepartment,
  getAreaTree,
  getCommonDictionary,
} from "@/api/common_api";

import { ArrowDown } from "@element-plus/icons";
import VueEvent from "../../utils/global_event";
import { ElMessage } from "element-plus";

export default {
  name: "dropdown-field",
  components: {
    ArrowDown,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
      default: null,
    },
    userTypeName: {
      type: String,
      default: "医护人员",
    },
  },
  data() {
    return {
      value: "",
      // 选择器数据
      pickerData: null,
      multiSelect: false,
      // label 字段
      labelField: "label",
      // value 字段
      valueField: "value",
      notify: false,
      pid: null,
      idMaps: new Map([
        [15, 3],
        [16, 4],
        [17, 5],
        [18, 6],
        [19, 7],
        [20, 8],
      ]),
    };
  },

  methods: {
    // 更新数据到表单里面
    confirm() {
      const nodes = this.$refs.picker.getCheckedNodes()[0];
      const { item } = this;
      item.ids = nodes.pathValues;
      item.value = nodes.text.replace(/\//g, "").replace(/\s+/g, "");
      item.error = !!item.value ? "" : "请从下拉框选择" + item.title;
      this.$emit("updateField", {
        index: this.currentIndex,
        item,
      });
      if (this.notify) {
        VueEvent.emit("hasPickedProfession", this.idMaps.get(item.ids[0]));
      }
    },
    loadData() {
      const conditions = new Map([
        // 区域，没啥好说的
        [
          "area",
          async () => {
            try {
              this.multiSelect = true;
              const { data } = await getAreaTree();
              // filter empty children field
              this.pickerData = data.map((province) => {
                const { area_name, area_id, children } = province;
                let obj = {
                  label: area_name,
                  value: area_id,
                };
                if (children.length > 0) {
                  obj.children = children.map((city) => {
                    const { area_name, area_id, children } = city;
                    let obj = {
                      label: area_name,
                      value: area_id,
                    };

                    if (children.length > 0) {
                      obj.children = children.map((area) => {
                        return {
                          label: area.area_name,
                          value: area.area_id,
                        };
                      });
                    }
                    return {
                      ...obj,
                    };
                  });
                }
                return {
                  ...obj,
                };
              });
            } catch (error) {
              this.$message.error(error);
            }
          },
        ],
        // 普通用户和医护用户填写信息不一样
        // 先选职业,再选职称
        [
          "profession",
          async () => {
            this.multiSelect = false;
            const { user_type } = this.item;
            const { data } = await getCommonDictionary(
              this.userTypeName === "非医护人员" ? 11 : 2
            );
            this.valueField = "id";
            this.labelField = "name";
            this.pickerData = data;
            this.notify = true;
            if (!typeof this.item.value === "string") {
              return;
            }
            if (this.item.value) {
              const neededItem = data.filter(
                (pro) => pro.name === this.item.value.trim()
              );
              if (Array.isArray(neededItem) && neededItem.length > 0) {
                const pid = neededItem[0]?.id;
                VueEvent.emit("hasPickedProfession", this.idMaps.get(pid));
              }
            }
          },
        ],
        // 普通用户没有职称
        [
          "job_title",
          async () => {
            this.multiSelect = false;
            const { data } = await getCommonDictionary(this.pid);
            this.valueField = "id";
            this.labelField = "name";
            this.pickerData = data;
            if (this.item?.ids.length > 0) {
              this.value = this.item.ids;
            } else {
              if (Array.isArray(data) && data.length > 0) {
                for (const item of data) {
                  if (item.name === this.value) {
                    this.item.value = item.name;
                    this.item.ids = [item.id];
                    this.value = item.name;
                    return;
                  }
                }
                const val = data[0].name;
                this.item.value = val;
                this.item.ids = [data[0].id];
                /**
                 * PC和H5的职称选择不同，
                 * H5 展示的是value （标题）
                 * PC 需要渲染到组件内部通过编号id来展示对应的选项
                 */
                this.value = [data[0].id];
                this.$emit("updateField", {
                  index: this.currentIndex,
                  item: this.item,
                });
              }
            }
          },
        ],
        // 职务与前者没有关联
        [
          "job",
          async () => {
            this.multiSelect = false;
            const { user_type } = this.item;
            const { data } = await getCommonDictionary(
              this.userTypeName === "非医护人员" ? 12 : 9
            );
            this.valueField = "id";
            this.labelField = "name";
            this.pickerData = data;
          },
        ],
        // 普通用户不显示科室，科室为二级联动
        [
          "depart",
          async () => {
            try {
              this.multiSelect = true;
              const { data } = await getDepartment();
              this.labelField = "name";
              this.valueField = "id";
              this.pickerData = data;
            } catch (error) {
              ElMessage.error(error);
            }
          },
        ],
        [
          "card_type",
          async () => {
            try {
              this.multiSelect = false;
              const { data } = await getCommonDictionary(10);
              this.labelField = "name";
              this.valueField = "id";
              this.pickerData = data;
            } catch (error) {
              ElMessage.error(error);
            }
          },
        ],
      ]);
      const execMethod = conditions.get(this.item.name);
      execMethod();
    },
  },
  mounted() {
    if (this.item.name !== "job_title") {
      if (this.item?.ids) {
        if (this.item.ids.length > 0) {
          this.value = this.item.ids;
        }
      } else {
        this.value = this.item.value;
      }
      this.loadData();
    } else {
      VueEvent.on("hasPickedProfession", (pid) => {
        this.pickerData = null;
        this.pid = pid;
        this.loadData();
      });
    }
  },
  beforeUnmount() {
    VueEvent.off("hasPickedProfession");
  },
};
</script>

<style scoped>
::v-deep(.el-cascader) {
  width: 364px;
  height: 51px;
  line-height: 51px;
}

::v-deep(.el-input__inner) {
  height: 51px;
  line-height: 51px;
}
</style>

<style lang="less" scoped>
.flex-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-field {
  width: 364px;
  height: 51px;
  line-height: 51px;
  background: #ffffff;
  border: 1px solid #d8e2f0;
  font-size: 14px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.label {
  font-size: 14px;
  width: 100px;
  font-weight: 400;
  color: #111111;
  text-align: right;
  span {
    color: #f56c6c;
  }
}
</style>
