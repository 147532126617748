<template>
  <div class="icon-wx">
<!--    <div v-show="showImg" @click="iconShow = !iconShow">-->
<!--      <img src="/src/assets/img/users/wx-code.png" alt="" />-->
<!--    </div>-->
    <div style="margin-top: 20px" v-show="appSetting && showImg && iconShow">
<!--      <el-collapse-transition>-->
<!--        <div class='box' v-show='appSetting.wecwhat_official_account'>-->
<!--          <div class="transition-box">-->
<!--            <img-->
<!--              :src="appSetting.wecwhat_official_account"-->
<!--              alt=""-->
<!--              style="width: 100px; height: 100px"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="tips">扫码关注公众号</div>-->
<!--          &lt;!&ndash; <div class="transition-box">-->
<!--            <img src="/src/assets/img/users/wx-code.png"-->
<!--                 alt="">-->
<!--          </div> &ndash;&gt;-->
<!--        </div>-->
        <div class='box' v-show='appSetting.sidebar_img1'>
          <div class="transition-box">
            <img
              :src="appSetting.sidebar_img1"
              alt=""
              style="width: 100px;"
            />
          </div>
          <div class="tips">{{appSetting.sidebar_title1}}</div>
        </div>
        <div class='box' v-show='appSetting.sidebar_img2'>
          <div class="transition-box">
            <img
              :src="appSetting.sidebar_img2"
              alt=""
              style="width: 100px;"
            />
          </div>
          <div class="tips">{{appSetting.sidebar_title2}}</div>
        </div>
        <div class='box' v-show='appSetting.sidebar_img3'>
          <div class="transition-box">
            <img
              :src="appSetting.sidebar_img3"
              alt=""
              style="width: 100px;"
            />
          </div>
          <div class="tips">{{appSetting.sidebar_title3}}</div>
        </div>
<!--      </el-collapse-transition>-->
    </div>
<!--    // TODO: 添加更多可配置项-->
    <div class="top-icon" @click="toTop" v-if="goTop">
      <img src="/src/assets/img/users/return.png" alt="" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "float-icon",
  setup() {
    const store = useStore();
    const iconShow = ref(true);
    const goTop = ref(false);
    const showImg = ref(true)
    const handleScroll = () => {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let scrollBottom =
        document.documentElement.scrollHeight -
          scrollTop;

      scrollTop > 30 ? (goTop.value = true) : (goTop.value = false);
      scrollBottom < 1400 ? (showImg.value = false) : (showImg.value = true);
    };
    const toTop = () => {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    };
    const handleClose = () => {
      iconShow.value = false
    }
    const handleShow = () => {
      iconShow.value = true
    }
    onMounted(() => {

      window.addEventListener("scroll", handleScroll, true);

      let width = window.innerWidth || document.documentElement.clientWidth;
      if (width < 1536) {
        handleClose()
      }
      window.addEventListener('resize', function(){
        let width = window.innerWidth || document.documentElement.clientWidth;
        //响应大小变化的处理
        if (width < 1536) {
          handleClose()
        } else {
          handleShow()
        }
      });
    });
    return {
      appSetting: computed(() => store.getters.getAppSetting),
      toTop,
      goTop,
      showImg,
      handleClose,
      iconShow,
    };
  },
};
</script>

<style lang="less" scoped>
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.icon-wx {
  position: fixed;
  //top: 55%;
  bottom: 100px;
  right: 50px;
  z-index: 111;
  width: 104px;
  text-align: center;

  .box {
    margin-bottom: 20px;
  }

  .transition-box {
    font-size: 0;
    border: 2px solid;
    border-image: linear-gradient(0deg, #4facfe, #00f2fe) 2 2;
    box-sizing: border-box;
    width: 104px;
    //height: 104px;
  }

  .tips {
    width: 100%;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: #0398FE;
    margin-top: 8px;
  }
}

.top-icon {
  text-align: center;
  margin-top: 20px;
}
</style>
