import { ElMessage } from "element-plus";
function guid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default class Scheduling {
  // 定时器
  timeout = null;
  count = 0;
  duration = 1000;
  fn = null;
  recordUid = guid();
  args = null;

  bindFn(fn, args) {
    this.fn = fn;
    this.args = args;
  }

  // 开始计时
  begin = () => {
    this.recordUid = guid();
    if (this.timeout) {
      clearInterval(this.timeout);
    }
    this.timeout = setTimeout(this.startRecord, this.duration);
  };

  // 开始记录
  startRecord = () => {
    try {
      this.count++;
      if (this.count >= 2) this.duration = 3000;
      if (this.count >= 10) this.duration = 5000;
      this.args.recordUid = this.recordUid;
      this.args.duration = this.duration / 1000;
      this.fn(this.args);
      clearInterval(this.timeout);
      this.timeout = setInterval(this.startRecord, this.duration);
    } catch (e) {
      ElMessage.error(e);
    }
  };

  // 停止记录，重置参数
  stop = () => {
    clearInterval(this.timeout);
    this.timeout = null;
    this.count = 0;
    this.args = null;
    this.recordUid = null;
  };

  // 暂停记录，不清理掉当前参数
  pause = () => {
    clearInterval(this.timeout);
  };
}
