import Layout from '@components/layout/Layout.vue'
// 直播页面
export default {
    path: '/live',
    component: Layout,
    meta: {
        transition: "slide"
    },
    children: [{
        path: 'detail',
        component: () =>
            import ('@pages/live/detail.vue')
    },
        {
            path: 'live',
            component: () =>
                import ('@pages/live/live.vue')
        },
        {
            path: 'intro-list',
            component: () =>
                import ('@pages/live/intro-list.vue')
        },
        {
            path: 'live-list',
            component: () =>
                import ('@pages/live/live-list.vue')
        },
        {
            path: 'playback-list',
            component: () =>
                import ('@pages/live/playback-list.vue')
        },
      //   {
      //     path: 'pic-live',
      //     component: () =>
      //         import ('@pages/live/pic-live.vue')
      // }
    ]
}