import Layout from "@components/layout/Layout.vue";
// 课程页面
export default {
    path: "/courses",
    component: Layout,
    meta: {
        transition: "slide",
    },
    children: [{
            path: "index",
            component: () =>
                import ("@pages/courses/index.vue"),
        },
        {
            path: "detail",
            component: () =>
                import ("@pages/courses/course-info.vue"),
        },
    ],
};