import Layout from "@components/layout/Layout.vue";
// 用户中心页面
export default {
  path: "/users",
  component: Layout,
  meta: { transition: "slide" },
  children: [
    {
      path: "index",
      component: () => import("@pages/users/index.vue"),
    },
  ],
};
