import request from '../utils/request'

// 获取软件版本
export function getSoftVersion({}) {
    return request.get('/common/soft/index', {})
}

// 获取指定版本
export function getAimedVersion(id) {
    return request.get(`/common/soft/${id}`, {})
}

// 获取用户协议
export function getAgreement() {
    return request.get(`/common/agreement/index`, {})
}

/**
 * REST 风格接口 获取某个资源
 * @param {} id
 * @returns
 */
// 获取指定协议
export function getAimedAgreement(id) {
    return request.get(`/common/agreement/${id}`, {})
}

// 获取科室树
export function getDepartment(params) {
    return request.get(`/common/department/tree`, { params })
}

/**
 * 普通类型的接口,获取某个集合
 * 获取指定区域列表
 * @param {*} params {
 *  area_id 区域id
 * }
 */
export function getArea(params) {
    return request.get(`/common/areas/lists`, params)
}

/**
 * 获取二级科室
 * @param {*} params {
 *  fid 父节点id
 * }
 */
export function getSubDepartment(id) {
    return request.get(`/common/department/${id}`, {})
}

// 获取APP设置
export function getAppSetting() {
    return request.get(`/common/settings`, {})
}

// 课程tab页获取已选科室
export function getDepartList() {
    return request.get(`/common/department/lists`, {})
}

// 课程tab页添加科室
export function getDepartAdd(params) {
    return request.post(`/common/department/add`, params)
}

// 提交实名认证
export function postAuth(params) {
    return request.post(`/user/auth`, params)
}

// 身份认证-字典列表
export function getCommonDictionary(id) {
    return request.get(`/common/dictionary/${id}`, {})
}

// 实名认证图片上传
export function getUploadAuth(params) {
    return request.post(`/uploader/auth`, params)
}

/**
 * 获取城市树
 * @param {} params
 * @returns
 */
export function getAreaTree() {
    return request.get(`/common/areas/tree`, {});
}

// 关于我们
export function aboutLists(params) {
    return request.get(`/about/lists`, params)
}

// 友情链接
export function friendLinkLists(params) {
    return request.get(`/friendLink/lists`, params)
}

// 页面路由表
export function getPageMap(){
    return request.get(`/page/lists`,{})
}

// 获取注册字段
export function getRegisterField(){
	return request.get(`/common/settings/register`,{})
}