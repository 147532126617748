<template>
  <div class="wrapper">
    <div class="certification">
      <i class="el-icon-close certification-close" @click="skipAuth"></i>
      <div class="cer-content">
        <h3>{{ isEdit ? "变更实名信息" : "实名认证" }}</h3>
        <div class="from-content">
          <p class="from-notice">为了给您提供更好的服务，请先完成认证</p>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="certificationForm"
            label-width="80px"
            class="demo-ruleForm"
          >
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入您的姓名"
                style="width: 50%"
              ></el-input>
            </el-form-item>
            <el-form-item label="证件类型" prop="card_type">
              <el-select
                v-model="ruleForm.card_type"
                placeholder="请选择证件类型"
                style="width: 50%"
              >
                <el-option
                  v-for="item in cardType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="证件号码"
              :prop="ruleForm.card_type == 10 ? 'id_card' : 'card'"
            >
              <el-input
                v-model="ruleForm.id_card"
                v-if="ruleForm.card_type == 10"
                placeholder="请输入证件号码"
              ></el-input>
              <el-input
                v-model="ruleForm.card"
                v-else
                placeholder="请输入证件号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="您的身份" prop="user_type">
              <el-select
                v-model="ruleForm.user_type"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="医护人员" value="1"></el-option>
                <el-option label="其他用户" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="isEdit"
              :label="ruleForm.passPhone"
              prop="phone"
            >
              <div>
                <span>
                  <el-input
                    v-model="ruleForm.verify_code"
                    maxlength="4"
                    placeholder="输入验证码"
                    style="width: 50%"
                  ></el-input>
                </span>
                <span
                  class="send-code"
                  @click="sendCode()"
                  :disabled="disabled"
                  :class="[disabled ? 'codeDisabled' : '']"
                >
                  {{
                    codeTextShow === 0
                      ? codeText.getCode
                      : codeTextShow === 1
                      ? codeText.countDown + "(" + count + "s)"
                      : codeText.countDown
                  }}
                </span>
              </div>
            </el-form-item>
            <div class="prompt">
              <i
                class="el-icon-warning-outline"
                style="color: #e57922; margin-right: 4px"
              ></i>
              <p>
                根据《中华人民共和国网络安全法》及其他行业要求，需要您完成卫生技术职业人员认证，敬请谅解。
              </p>
            </div>
            <div
              v-if="isEdit"
              class="submit"
              :class="{
                'submit-button':
                  ruleForm.name &&
                  ruleForm.card_type &&
                  (ruleForm.id_card || ruleForm.card) &&
                  ruleForm.user_type,
              }"
            >
              <el-button
                type="primary"
                @click="submitForm('certificationForm')"
              >
                提交
              </el-button>
            </div>
            <div
              v-else
              class="submit"
              :class="{
                'submit-button':
                  ruleForm.name &&
                  ruleForm.card_type &&
                  (ruleForm.id_card || ruleForm.card) &&
                  ruleForm.user_type,
              }"
            >
              <el-button
                type="primary"
                @click="submitForm('certificationForm')"
              >
                下一步
              </el-button>
              <p class="jump">
                <span style="cursor: pointer" @click="skipAuth">跳过</span>
              </p>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script>
import { onMounted, ref } from "vue";
import { getCommonDictionary, postAuth } from "../../api/common_api";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import { auth, profile } from "../../api/personal_api";
import { getVerifyCode } from "../../api/auth_api";

export default {
  name: "Certification",
  emits: ["close", "needIdentity"],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // fetch refs
    const certificationForm = ref(null);
    // define form
    const ruleForm = ref({
      name: null,
      card_type: null,
      id_card: null,
      user_type: null,
      verify_code: null,
      phone: null,
      passPhone: null,
    });
    // 获取验证码 start
    const codeText = ref({
      getCode: "获取验证码",
      countDown: "重新发送",
    });
    const codeTextShow = ref(0);
    const count = ref(59);
    const hash = ref(null);
    const disabled = ref(false);
    // 获取验证码 end

    // skip auth
    const skipAuth = () => {
      window.localStorage.skipAuth = "1";
      context.emit("close");
    };
    // define card type array
    const cardType = ref([]);
    // fetch card type
    const getCardType = async () => {
      const { data } = await getCommonDictionary(10);
      cardType.value = data;
    };
    const store = useStore();

    const loading = ref(false);
    // to certification
    const toCertification = async () => {
      try {
        if (loading.value) return;
        loading.value = true;
        if (ruleForm.value.card_type !== 10) {
          ruleForm.value.id_card = ruleForm.value.card;
        }
        const { msg, errorCode } = await postAuth(ruleForm.value);
        if (errorCode !== 0) {
          ElMessage.error(msg);
          loading.value = false;
          return;
        }
        const user = await profile();
        store.commit("USER_LOGIN", {
          userInfo: user.data,
        });
        const authInfo = await auth();
        store.commit("SET_AUTH_INFO", {
          authInfo: authInfo.data,
        });
        ElMessage.success(msg);
        loading.value = false;
        context.emit("close");
        // if current user need identity
        if (parseInt(user.data.auth_status) === 0) {
          context.emit("needIdentity");
        } else {
          window.location.reload();
        }
      } catch (e) {
        ElMessage.error(e);
        loading.value = false;
      }
    };
    // 发送验证码并进行判断
    const sendCode = async () => {
      try {
        const { data, msg } = await getVerifyCode({
          phone: ruleForm.value.phone,
        });
        ElMessage.success(msg);
        hash.value = data.hash;
        codeTextShow.value = 1;
        disabled.value = true;
        let interval = setInterval(() => {
          --count.value;
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
          count.value = 59;
          codeTextShow.value = 2;
          disabled.value = false;
        }, 60000);
      } catch (error) {
        ElMessage.error(error);
      }
    };
    onMounted(async () => {
      await getCardType();
      const { data } = await profile();
      ruleForm.value.phone = data.phone;
      ruleForm.value.passPhone = data.phone.replace(
        data.phone.slice(-8, -4),
        "****"
      );
      // if (props.isEdit) {
      //   return;
      // }
      ruleForm.value.name = data.name;
      if (data.user_type === 0 || data.user_type === 1) {
        ruleForm.value.user_type = "1";
      } else {
        ruleForm.value.user_type = "2";
        // ruleForm.value.card_type = parseInt(data.card_type)
        // ruleForm.value.id_card = data.id_card;
      }

      // if (props.isEdit) {
      ruleForm.value.card_type = data.card_type
        ? parseInt(data.card_type)
        : null;
      ruleForm.value.card = data.id_card;
      ruleForm.value.id_card = data.id_card;
      // store.state.authInfo
      // }
    });

    return {
      certificationForm,
      toCertification,
      ruleForm,
      skipAuth,
      cardType,
      sendCode,
      rules: ref({
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, max: 4, message: "长度为 2 到 4 个汉字", trigger: "blur" },
        ],
        card_type: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        id_card: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          { min: 18, max: 18, message: "身份证号为18个字符", trigger: "blur" },
        ],
        card: [{ required: true, message: "请输入证件号码", trigger: "blur" }],
        user_type: [
          { required: true, message: "请选择您的身份", trigger: "change" },
        ],
      }),
      codeText,
      codeTextShow,
      count,
      hash,
      disabled,
    };
  },

  methods: {
    // submit form
    async submitForm(formName) {
      // fetch updated dom
      await this.$nextTick();
      // validate form
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // submit form to backend
          this.toCertification();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 777;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.08);
}
.send-code {
  display: inline-block;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #0398FE;
  border-radius: 3px;
  width: 105px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  border: 0;
  user-select: none;
}
.mask {
  background-color: rgb(0, 0, 0);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.certification {
  width: 560px;
  height: 654px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 16px;
  z-index: 1111;
  box-shadow: 0 11px 14px 2px rgba(32, 32, 32, 0.17);
  padding: 30px 70px 30px;
  position: relative;

  .certification-close {
    font-size: 32px;
    position: absolute;
    right: 30px;
    top: 20px;
    color: #202020;
  }

  .cer-content {
    h3 {
      text-align: center;
      color: #111111;
      font-size: 20px;
      padding: 20px 0;
      border-bottom: 1px solid #dddddd;
    }

    .from-content {
      .from-notice {
        text-align: center;
        color: #e57922;
        font-size: 12px;
        margin: 15px 0 10px;
      }

      ::v-deep(.el-form-item__label) {
        color: #201f1f;
      }

      ::v-deep(.el-input--suffix) {
        width: 100%;
      }

      .prompt {
        line-height: 13px;
        margin-top: 11px;
        display: flex;
        padding-left: 80px;

        img {
          width: 15px;
          height: 15px;
        }

        p {
          font-size: 12px;
          color: #999999;
        }
      }

      .submit {
        text-align: center;
        margin-top: 50px;

        .el-button {
          width: 200px;
          height: 44px;
          background: #dddddd;
          color: #fff;
          border-radius: 22px;
          border: none;
        }

        .jump {
          margin-top: 20px;

          span {
            color: #0398FE;
          }
        }
      }

      .submit-button {
        .el-button {
          background: #0398FE;
        }
      }
    }
  }
}
</style>
<style>
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "*";
}

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
  content: "";
}
</style>
