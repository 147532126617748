<template>
  <div class="flex-center">
    <div class="label">
      {{ item.title }}<span v-if="parseInt(item.required) === 1">*</span>:
    </div>
    <div v-if="item" class="select-field">
      <el-radio-group v-model="value" :disabled="edit" @change="radioChange">
        <el-radio :label="item.name" v-for="item in list">{{
          item.name
        }}</el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "radio-field",
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {
        return null;
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      value: "",
    };
  },
  methods: {
    // 选中某个单选框时，由radio时触发
    radioChange(e) {
      const { item } = this;
      item.value = e;
      item.error = !!e ? "" : "请选择一项";
      this.$emit("updateField", {
        index: this.currentIndex,
        item,
      });
    },
  },
  mounted() {
    const { options, value } = this.item;
    this.list = options.map((option) => {
      return {
        name: option,
      };
    });
    this.value = value;
  },
};
</script>

<style lang="less" scoped>
.flex-center {
  width: 100%;
  display: flex;
  align-items: center;
}

.select-field {
  width: 364px;
  ::v-deep(.el-radio-group) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.label {
  font-size: 14px;
  width: 90px;
  font-weight: 400;
  color: #111111;
  text-align: right;
  margin-right: 10px;
  span {
    color: #f56c6c;
  }
}
</style>
