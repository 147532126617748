import Layout from "@components/layout/Layout.vue";
// 政策页面
export default {
  path: "/index/experts",
  component: Layout,
  meta: {
    transition: "slide",
  },
  children: [
    {
      path: "index",
      component: () => import("@pages/experts/index.vue"),
    },
    {
      path: "detail",
      component: () => import("@pages/experts/detail.vue"),
    },
    {
      path: "userdetail",
      component: () => import("@pages/experts/userdetail.vue"),
    }, // 用户库详情页面
  ],
};
