<template>
  <div
    class="sendVerifyCode"
    :class="{ wait: sendCodeSeconds > 0 }"
    @click="checkMobile"
  >
    {{ sendCodeSeconds > 0 ? sendCodeSeconds + "秒后可重试" : "获取验证码" }}
  </div>
</template>
<script>
import { getVerifyCode } from "../../api/auth_api";
import { debounce } from "lodash";
let timer = null;
export default {
  name: "verifyCode",
  emits: ["phoneError", "send"],
  props: {
    mobile: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sendCodeSeconds: 0,
    };
  },
  beforeUnmount() {
    this.clean();
  },
  methods: {
    async sendAction() {
      try {
        await getVerifyCode({
          phone: this.mobile,
        });
        this.$message.success("短信发送成功");
        return true;
      } catch (e) {
        return false;
      }
    },

    checkMobile: debounce(function () {
      if (!/^1[3-9]\d{9}$/.test(this.mobile)) {
        this.$emit("phoneError");
        return;
      }
      this.sendCode();
    }, 300),

    // 发送验证码
    async sendCode() {
      // 如果定时器没有清空
      if (timer) return;
      // 获取当前时间戳
      let currentTimeStamp = Math.floor(new Date().getTime() / 1000);
      // 读取本地变量
      let lastSendMsgTime = parseInt(
        localStorage.getItem("lastSendMsgTime") || 0
      );
      // 这种情况主要是APP被异常退出,倒计时尚未结束
      let leftSeconds = 60 - (currentTimeStamp - lastSendMsgTime);
      // 如果没有发送过或者已经超过了间隔时间
      if (!lastSendMsgTime || lastSendMsgTime === -1 || leftSeconds <= 0) {
        if (!(await this.sendAction())) {
          return;
        }
        this.$emit("send");
        this.start(60);
        localStorage.setItem("lastSendMsgTime", currentTimeStamp.toString());
      } else {
        this.$message.warning("请稍候再试");
        this.start(leftSeconds);
      }
    },
    // 启动定时器
    start(seconds) {
      if (!seconds) return;
      this.sendCodeSeconds = seconds;
      timer = setInterval(() => {
        if (this.sendCodeSeconds > 0) {
          this.sendCodeSeconds--;
          return;
        }
        this.clean();
        // 清理定时器并且置空
        localStorage.setItem("lastSendMsgTime", "-1");
      }, 1000);
    },
    clean() {
      clearInterval(timer);
      timer = null;
      this.sendCodeSeconds = 0;
    },
    cleanSendTime() {
      localStorage.setItem("lastSendMsgTime", "-1");
      this.clean();
    },
  },
};
</script>

<style lang="less" scoped>
.sendVerifyCode {
  box-sizing: border-box;
  //padding: 13px 0 13px 16px;
  color: #0398FE;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
}

.wait {
  color: #999999 !important;
}
</style>
