<template>
  <div class="aff-wrapper">
    <div class="aff-dialog" v-loading="switching">
      <div class="title">完善信息</div>
      <Close class="close-btn" @click="close" />
      <div class="form-container">
        <div class="form">
          <div class="form-item" v-for="(item, index) in form" :key="item.name">
            <component
              :is="calcComponent(item.type)"
              :item="item"
              :current-index="index"
              :user-type="professionUser ? 1 : 2"
              :userTypeName="userTypeName"
              @updateField="updateField"
            >
            </component>
            <p class="error-tips">{{ item.error }}</p>
          </div>
        </div>
        <div class="btn-group">
          <div class="submit-btn" @click="submitForm">提 交</div>
          <div
            v-if="btnShow === 0 && cardType == '1'"
            class="identity-switch"
            @click="switchUserType"
          >
            {{ professionUser ? "非医护人员" : "医护人员" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Close } from "@element-plus/icons";
import { onMounted, ref, computed, onUnmounted, nextTick } from "vue";
import { submitMeetingForm } from "../../api/meeting_form_api";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  auth,
  profile,
  editProfile,
  getUserAuth,
} from "../../api/personal_api";
import TextField from "../dynamic-form/text-field.vue";
import ImageField from "../dynamic-form/image-field.vue";
import CheckboxField from "../dynamic-form/checkbox-field.vue";
import RadioField from "../dynamic-form/radio-field.vue";
import DropdownField from "../dynamic-form/new-dropdown-field.vue";
import AutoComplete from "../dynamic-form/auto-complete.vue";

export default {
  emits: ["close-dialog"],
  name: "auto-field-form",
  components: {
    Close,
    "text-field": TextField,
    "image-field": ImageField,
    "checkbox-field": CheckboxField,
    "radio-field": RadioField,
    "dropdown-field": DropdownField,
    "auto-complete": AutoComplete,
  },
  props: {
    meetingForm: {
      type: Array,
    },
  },

  setup(props, context) {
    const typeMap = new Map([
      [1, { name: "text-field", tips: "填写" }],
      [2, { name: "image-field", tips: "上传" }],
      [3, { name: "radio-field", tips: "选择" }],
      [4, { name: "checkbox-field", tips: "多选" }],
      [5, { name: "dropdown-field", tips: "从下拉框选择" }],
      [6, { name: "auto-complete", tips: "填写关键字并从下拉框选择" }],
    ]);
    const store = useStore();
    const userInfo = computed(() => store.getters.getUserInfo);
    const calcComponent = (type) => {
      type = parseInt(type);
      return typeMap.get(type).name;
    };
    const active = ref(0);
    // 表单
    const form = ref(null);
    const information = ref({
      // depart_id: null,
      // depart: null,
      // province_id: null,
      // city_id: null,
      // area_id: null,
    });

    // 更新表单
    const updateField = (args) => {
      form.value[args[0]] = args[1];
    };

    const close = () => {
      if (active.value === 0) {
        window.localStorage.skipAuth = "1";
      }
      window.localStorage.perfectInfo = "1";
      context.emit("close-dialog");
    };
    /**
     * 判断是否
     * 是医护人员还是非医护人员
     * @type {Ref<UnwrapRef<string>>}
     */
    const userTypeName = ref("医护人员");
    const professionUser = ref(true);

    const switchUserType = async () => {
      if (switching.value) return;
      switching.value = true;
      await nextTick();
      const meetingForm = JSON.parse(JSON.stringify(props.meetingForm));
      professionUser.value = !professionUser.value;
      professionUser.value
        ? (userTypeName.value = "医护人员")
        : (userTypeName.value = "非医护人员");
      form.value = [];
      await nextTick();
      form.value = meetingForm.filter(
        (item) =>
          (item.user_type === (professionUser.value ? 1 : 2) ||
            item.user_type === 0) &&
          item.name !== "user_type"
      );
      setTimeout(() => {
        switching.value = false;
      }, 1200);
    };
    const validForm = () => {
      try {
        if (!form.value) return false;
        let valid = true;
        for (const item of form.value) {
          if (parseInt(item.required) === 1) {
            if (
              (typeof item.value === "string" &&
                item.value.trim().length === 0) ||
              !item.value
            ) {
              item.error =
                "请" + typeMap.get(parseInt(item.type)).tips + item.title;
              valid = false;
              continue;
            }
            if (Array.isArray(item.ids) && item.ids.length === 0) {
              item.error = `请在${item.title}下拉框输入后选择一项`;
              valid = false;
              continue;
            }
            if (Array.isArray(item.value) && item.value.length === 0) {
              item.error = "请完成选择";
              valid = false;
              continue;
            }
            item.error = "";
          }
        }
        return valid;
      } catch (e) {
        console.error(e);
      }
    };
    const route = useRoute();
    const router = useRouter();
    let loading = false;
    // 页面跳转
    const jump = () => {
      router.push({
        path: "/tabs/index",
        query: {
          ...route.query,
        },
      });
    };
    // 提交表单
    const submitForm = async () => {
      try {
        let isIdCard = ref(false);
        if (loading) return;
        loading = true;
        if (!validForm()) {
          loading = false;
          return;
        }
        let includesArr = ["profession", "job_title", "job"];
        form.value.forEach((field) => {
          if (field.name === "id_card") isIdCard.value = true;
          // 删除检验字段
          delete field.error;
          if (field.name === "area") {
            information.value.province_id = field.ids[0];
            information.value.city_id = field.ids[1];
            information.value.area_id = field.ids[2];
          } else if (includesArr.includes(field.name)) {
            information.value[field.name + "_id"] = field.ids[0];
            information.value[field.name] = field.value;
          } else if (field.name === "depart") {
            information.value.depart = field.value;
            information.value.depart_id = field.ids[0];
            information.value.depart_ids = field.ids[1];
          } else if (field.name === "hospital_name") {
            information.value.hospital_name = field.value;
            information.value.hospital_id = field.ids[0];
          } else if (field.name === "card_type") {
            information.value.card_type = field.ids[0];
          } else if (field.name === "sex") {
            field.value === "男"
              ? (information.value.sex = 1)
              : (information.value.sex = 2);
          } else {
            information.value[field.name] = field.value;
          }
        });

        if (cardType.value == "0") {
          information.value.user_type = userInfo.value.user_type;
        } else {
          userTypeName.value === "医护人员"
            ? (information.value.user_type = 1)
            : (information.value.user_type = 2);
        }
        const obj = {
          name: information.value.name
            ? information.value.name
            : store.state.userInfo.name,
          card_type: information.value.card_type,
          id_card: information.value.id_card,
          user_type: information.value.user_type,
        };
        if (isIdCard.value === true) {
          await getUserAuth(obj);
        }
        const { msg } = await editProfile(information.value);
        ElMessage.success(msg);
        active.value = 1;
        setTimeout(() => {
          context.emit("close-dialog");
          window.localStorage.perfectInfo = "1";
          window.location.reload();
        }, 1000);
      } catch (error) {
        loading = false;
        ElMessage.error(error);
      }
    };
    const switching = ref(false);
    const btnShow = ref(0);
    const cardType = ref("0");
    // 组件挂载
    onMounted(() => {
      const meetingForm = JSON.parse(JSON.stringify(props.meetingForm));
      const uType =
        userInfo.value.user_type === 0 ? 1 : userInfo.value.user_type;
      meetingForm.forEach((item) => {
        if (item.name === "user_type") {
          /* 身份类型开启返回1 */
          cardType.value = "1";
          return;
        }
      });
      form.value = meetingForm.filter(
        (item) =>
          (item.user_type === uType || item.user_type === 0) &&
          item.name !== "user_type"
      );
      props.meetingForm = form.value;
      if (form.value.length === 0) {
        jump();
        return;
      }
      professionUser.value = uType === 1;
      /**
       * 切换按钮状态是否隐藏
       * @type {number}
       */
      btnShow.value = userInfo.value.user_type;
      document.body.style.overflow = "hidden";
    });
    onUnmounted(() => {
      document.body.style.overflow = "";
    });
    // 用户类型
    return {
      userInfo,
      form,
      professionUser,
      switching,
      btnShow,
      cardType,
      userTypeName,
      calcComponent,
      updateField,
      submitForm,
      close,
      switchUserType,
    };
  },
};
</script>
<style lang="less" scoped>
.aff-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.32);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  z-index: 999;

  .aff-dialog {
    width: 690px;
    min-height: 300px;
    max-height: 700px;
    height: auto;
    background: #ffffff;
    box-shadow: 0 4px 14px 2px rgba(32, 32, 32, 0.17);
    border-radius: 16px;
    position: relative;

    .title {
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      color: #111111;
      text-align: center;
      margin-top: 20px;
    }

    .close-btn {
      position: absolute;
      width: 24px;
      height: 24px;
      color: #999999;
      right: 24px;
      top: 26px;
      cursor: pointer;
    }

    .form-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-height: 652px;

      .form {
        margin: 42px 113px 0 105px;
        overflow: hidden scroll;
        max-height: 492px;
        min-height: 100px;
        box-sizing: border-box;

        .form-item {
          margin-bottom: 20px;

          .error-tips {
            width: 100%;
            color: #f56c6c;
            font-size: 14px;
            box-sizing: border-box;
            padding-left: 100px;
            margin-top: 10px;
          }
        }
      }

      .btn-group {
        margin-bottom: 27px;
        height: 120px;

        .submit-btn {
          line-height: 43px;
          text-align: center;
          font-weight: 400;
          font-size: 15px;
          color: #ffffff;
          margin: 20px auto 0 auto;
          cursor: pointer;
          width: 200px;
          height: 43px;
          background: #0398FE;
          border-radius: 21px;
          user-select: none;
        }
        .identity-switch {
          width: 200px;
          height: 43px;
          background: #ffffff;
          border: 1px solid #0398FE;
          border-radius: 21px;
          text-align: center;
          line-height: 43px;
          color: #0398FE;
          margin: 10px auto 0px auto;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
}
</style>
