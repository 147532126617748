// 头部数据
export const headerData = [
  {
    text: "首页",
    link: "/index",
  },
  {
    text: "会议直播",
    link: "/live",
  },
  {
    text: "课程学习",
    link: "/course",
  },
];
// 尾部数据
export const footerData = {
  aboutUs: [
    {
      name: "公司简介",
      link: "",
    },
    {
      name: "关于我们",
      link: "",
    },
    {
      name: "相关资讯",
      link: "",
    },
    {
      name: "免责声明",
      link: "",
    },
    {
      name: "入驻流程",
      link: "",
    },
  ],
  contractUs: [
    {
      name: "问题反馈",
      link: "",
    },
    {
      name: "客服电话: 4000-876-388",
      link: "",
    },
    {
      name: "公司地址：济南市历下区泺源大街102号祥恒广场18F",
      link: "",
    },
  ],
  platforms: [
    {
      name: "客户端下载",
      imgLink: "",
    },
    {
      name: "公众号关注",
      imgLink: "",
    },
    {
      name: "微信小程序",
      imgLink: "",
    },
  ],
};
// 网站信息
export const siteInfo = [
  {
    name: "Copyright ©2019-2021 www.jiyuanyixue.com lnc.ALL Rights Reserved",
    link: "",
  },
  {
    name: "山东基源信息科技有限公司 版权所有  客服热线：4000-876-388",
    link: "",
  },
  {
    name: "ICP备案号：鲁ICP备19004320号-1",
    link: "https://beian.miit.gov.cn/#/Integrated/index",
  },
  {
    name: "互联网药品信息服务：鲁-经营性-2019-0015",
    link: "",
  },
];

// 友情链接
export const outerLinks = [
  {
    name: "基源科技",
    link: "http://www.jiyuankeji.cn",
  },
  {
    name: "百度",
    link: "https://www.baidu.com",
  },
  {
    name: "google",
    link: "https://www.google.com",
  },
];
