import Layout from '@components/layout/Layout.vue'
// 积分页面
export default {
    path: '/integral',
    component: Layout,
    meta: { transition: "slide" },
    children: [{
        path: 'index',
        component: () =>
            import ('@pages/integral/index.vue')
    }, {
        path: 'product-info',
        component: () =>
            import ('@pages/integral/product-info.vue')
    }, {
        path: 'submit-order',
        component: () =>
            import ('@pages/integral/submit-order.vue')
    }, {
        path: 'video',
        component: () =>
            import ('@pages/integral/video.vue')
    }]
}