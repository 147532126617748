<template>
  <transition name="slide-fade">
    <div class="wrapper" v-if="visible">
      <div class="charge-modal">
        <div class="head">
          <div class="title">充值金币</div>
          <close class="close-btn" @click="closeDialog" />
        </div>
        <div class="body">
          <p class="rate">1人民币=10金币</p>
          <div class="money-blocks">
            <div
              class="money-block"
              :class="{ active: selectedMoneyBlock === index }"
              v-for="(item, index) in commonFee"
              :key="item.num"
              @click="selectPlan(index)"
            >
              <p class="money"><span>￥</span>{{ item.num }}</p>
              <p class="coins">{{ item.num * 10 }}金币</p>
            </div>
          </div>
          <div class="other-amount">
            <p>其他金额:</p>
            <input v-model="inputAmount" type="number" @input="calcAmount" />
            <p>金币</p>
            <div class="confirm-btn" @click="initQRCode">确定</div>
          </div>
        </div>
        <el-divider class="divider" />
        <div class="footer" v-loading="!orderInfo">
          <div class="body" v-if="orderInfo">
            <div class="type">
              <img src="../../assets/img/common/scan.png" alt="扫码" />
              扫码支付
            </div>
            <div class="main">
              <div class="left">
                <canvas ref="wechatScanCode"></canvas>
              </div>
              <div class="right">
                <div class="money">
                  ￥<span>{{ orderInfo.amount }}</span>
                </div>
                <div class="tips">
                  <p>请使用微信扫一扫</p>
                  <p>扫描二维码支付</p>
                </div>
                <div class="remains-times">
                  剩余支付时间
                  <span class="countdown-time">{{
                    orderInfo.expireTimeFormat
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { nextTick, onMounted, ref } from "vue";
import VueEvent from "../../utils/global_event";
import { Close } from "@element-plus/icons";
import { chargeGold, validateChargeStatus } from "@api/gold_api";
import { ElMessage } from "element-plus";
import QRCode from "qrcode";
import TimeUtils from "../../utils/time";
import { profile } from "@api/personal_api";
import { useStore } from "vuex";
const commonFee = [
  { num: 1 },
  { num: 2 },
  { num: 5 },
  { num: 10 },
  { num: 50 },
  { num: 100 },
];
export default {
  name: "charge-modal",
  components: {
    Close,
  },
  setup() {
    const visible = ref(false);
    const selectedMoneyBlock = ref(0);
    const inputAmount = ref(0);
    const isInt = (num) => {
      if (!isNaN(num) && num % 1 === 0) {
        return true;
      } else {
        return false;
      }
    };
    const calcAmount = (e) => {
      selectedMoneyBlock.value = -1;
      const val = parseInt(e.target.value);
      if (val <= 0) {
        ElMessage.warning("单次最少充值1金币");
        inputAmount.value = 1;
        return;
      }

      if (val > 1e8) {
        ElMessage.warning("单次最多充值" + 1e8 + "金币");
        inputAmount.value = 1e8;
        return;
      }
      inputAmount.value = val;
    };
    const orderInfo = ref(null);
    let countdownTimer = null;
    let queryTimer = null;
    const calcTime = (time) => {
      const { remains, days, hours, minutes, seconds } =
        time.getRemainsEachUnit();
      if (remains <= 0) {
        clearInterval(countdownTimer);
        orderInfo.value = null;
        // 时间结束重新调用接口更新二维码
        initQRCode();
      }
      if (orderInfo.value) {
        let h = days > 0 ? days * 24 + hours : hours;
        let m = h > 0 ? h * 60 + minutes : minutes;
        orderInfo.value.expireTimeFormat = `${m}:${seconds}`;
      }
    };

    const clearTimers = () => {
      if (countdownTimer) {
        clearInterval(countdownTimer);
        countdownTimer = null;
      }
      if (queryTimer) {
        clearInterval(queryTimer);
        queryTimer = null;
      }
    };
    const wechatScanCode = ref(null);

    const initQRCode = async () => {
      orderInfo.value = null;
      await nextTick();
      const money =
        selectedMoneyBlock.value > -1
          ? commonFee[selectedMoneyBlock.value].num
          : (inputAmount.value / 10).toFixed(1);
      const { data } = await chargeGold({
        trade_type: "scan",
        amount: money * 100,
      });
      orderInfo.value = {
        amount: (data.total_amount / 100).toFixed(2),
        id: data.id,
        expireTime: data.expired_at,
        link: data.credential.code_url,
      };
      await nextTick();
      const elem = wechatScanCode.value;
      QRCode.toCanvas(elem, orderInfo.value.link, function (error) {
        if (error) ElMessage.error("生成二维码失败，请重试");
      });
      const time = new TimeUtils(orderInfo.value.expireTime, "date");
      // clear timer
      clearTimers();
      calcTime(time);
      countdownTimer = setInterval(() => {
        calcTime(time);
      }, 1000);
      try {
        queryTimer = setInterval(async () => {
          const { data } = await validateChargeStatus({
            id: orderInfo.value.id,
          });
          if (data.paid) {
            orderInfo.value = null;
            ElMessage.success("充值成功");
            clearTimers();
            // selectPlan(selectedMoneyBlock.value)
            // selectPlan(0)
            await closeDialog();
          }
        }, 3000);
      } catch (e) {
        ElMessage.error(e);
      }
    };

    const selectPlan = (index) => {
      inputAmount.value = commonFee[index].num * 10;
      selectedMoneyBlock.value = index;
      initQRCode();
    };

    const store = useStore();
    const closeDialog = async () => {
      clearTimers();
      orderInfo.value = null;
      visible.value = false;
      const { data } = await profile();
      store.commit("USER_LOGIN", {
        userInfo: data,
      });
    };

    onMounted(() => {
      // document.querySelector('.el-dialog').style.setProperty()
      VueEvent.on("showChargeModal", (amount) => {
        visible.value = true;
        if (amount) {
          selectedMoneyBlock.value = -1;
          inputAmount.value = amount;
          initQRCode();
          return;
        }
        selectPlan(0);
      });
    });
    return {
      commonFee,
      visible,
      selectedMoneyBlock,
      inputAmount,
      orderInfo,
      wechatScanCode,
      calcAmount,
      selectPlan,
      closeDialog,
      initQRCode,
    };
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(10px);
}

.wrapper {
  background-color: rgba(0, 0, 0, 0.22);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .charge-modal {
    width: 690px;
    height: auto;
    background: white;
    border-radius: 16px;

    .head {
      padding: 21px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #111111;
      font-weight: 500;
      font-size: 18px;

      .close-btn {
        width: 24px;
        height: 24px;
        color: #cccccc;
        cursor: pointer;
      }
    }

    .body {
      box-sizing: border-box;
      padding: 10px 83px;

      .rate {
        font-size: 18px;
        font-weight: 400;
        color: #888888;
      }

      .money-blocks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .money-block {
          margin-top: 20px;
          width: 148px;
          height: 120px;
          border-radius: 10px;
          background: #ffffff;
          border: 1px solid #dddddd;
          user-select: none;
          cursor: pointer;

          .money {
            text-align: center;
            margin-top: 26px;
            font-size: 34px;
            font-weight: bold;
            color: #05C064;

            span {
              font-size: 14px;
              margin-right: 4px;
            }
          }

          .coins {
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            color: #888888;
          }
        }

        .active {
          background: rgba(247, 90, 83, 0.4) !important;
          border: 1px solid #05C064 !important;

          .coins {
            color: #05C064 !important;
          }
        }
      }

      .other-amount {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;

        p:first-child {
          font-size: 18px;
          font-weight: 400;
          color: #111111;
        }

        input {
          width: 164px;
          height: 51px;
          background: #ffffff;
          border: 1px solid #dddddd;
          outline: none;
          margin-left: 30px;
          margin-right: 11px;
          padding: 0 12px;
          box-sizing: border-box;
          font-size: 16px;
          border-radius: 4px;
        }

        p:last-child {
          font-size: 18px;
          font-weight: 400;
          color: #888888;
        }

        .confirm-btn {
          width: 88px;
          height: 40px;
          background: #0398FE;
          border-radius: 10px;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          margin-left: 40px;
          cursor: pointer;
        }
      }
    }

    .divider {
      width: 524px;
      margin: auto;
    }

    .footer {
      width: 524px;
      height: 217px;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 10px;
      margin: 20px auto;
      box-sizing: border-box;

      .body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;

        .type {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 400;
          color: #888888;

          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            object-fit: cover;
          }
        }
      }

      .main {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        .left {
          width: 144px;
          height: 144px;
          border: 1px solid #dddddd;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 30px;

          img {
            width: 120px;
            height: 120px;
          }
        }

        .right {
          height: 144px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .money {
            font-size: 16px;
            font-weight: bold;
            color: #05C064;

            span {
              font-size: 34px;
            }
          }

          .tips {
            font-size: 16px;
            font-weight: 400;
            color: #888888;
            line-height: 20px;
          }

          .remains-times {
            font-size: 14px;
            font-weight: 400;
            color: #888888;
            margin-top: 20px;

            .countdown-time {
              color: #05C064;
              margin-left: 8px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
</style>
