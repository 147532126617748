import request from '../utils/request'

const prefix = '/search'

// 热搜列表
export function getHotSearch(params) {
    return request.get(`${prefix}/hot`, params)
}

// 搜索添加
export function addSearchRecord(params) {
    return request.post(`${prefix}/add`, params)
}

// 用户搜索
export function userSearch(params) {
    return request.post(`${prefix}/user`, params)
}

// 会议搜索
export function meetSearch(params) {
    return request.post(`${prefix}/meeting?per_page=12`, params)
}

// 课程搜索
export function courseSearch(params) {
    return request.post(`${prefix}/course?per_page=12`, params)
}

// 综合搜索
export function comprehensiveSearch(params) {
    return request.post(`${prefix}/fulltext`, params)
}

// 企业搜索
export function getCompanySearch(params) {
    return request.post(`${prefix}/company`, params)
}

// 医院搜索
export function getHospitalSearch(params) {
    return request.post(`${prefix}/hospital`, params)
}

// 专家搜索
export function expertSearch(params) {
    return request.get(`/doctor/lists`, {params})
}

// 视频搜索
export function videoSearch(params) {
    return request.post(`/search/video?per_page=12`, params)
}



// 关注专家
export function followExpert(id) {
    return request.post(`/doctor/follow/${id}`)
}