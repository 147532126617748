import { createStore } from "vuex";
import SocketUtils from "../utils/socket_handler";
import { getAppSetting } from "../api/common_api";
import LoggerUtils from "../utils/LoggerUtils";

// create vuex instance
const store = createStore({
  state() {
    return {
      userInfo: null,
      token: "",
      authInfo: null,
      connection: null,
      certificationVisible: false,
      appSetting: {},
      logger: null,
      tabsName: "",
      videoBack: {} /*回放视频播放信息*/,
      videoTimes: 0 /*回放视频视频时长*/,
      backSpeedFlag: false, // 回放视频拖拽快进权限
      msgPermission: "", // 评论权限 ：实名认证/注册用户
      changeVideoFlag: false, // 点击video列表中的某一项时, 上一个播放的视频会触发视频暂停事件,视频暂停事件会发送视频结束相关的日志请求, 在这里打个标记, 当视频切换的时候, 不发送请求
      video_all_complete: false, // 节点任务是否全部完成
      live_all_complete: false, // 节点任务是否全部完成
      taskNodes: [], // 任务节点
      underwayNode: {}, // 进行中的任务节点
      completeUnderwayNode: false, // 进行中节点已完成
      nowCompleteNode: {}, // 刚完成的节点
    };
  },
  getters: {
    // 获取用户信息
    getUserInfo: (state) => state.userInfo,
    // 获取用户实名认证状态
    getUserIde: (state) => !!state.userInfo?.auth_data?.identified,
    // 获取vuex的token
    getToken: (state) => "Bearer " + state.token,
    // 获取鉴权信息
    getAuthInfo: (state) => state.authInfo,
    // 跳过认证
    isSkipAuth: () => {
      return window.localStorage.skipAuth === "1";
    },
    // 获取socket链接
    getSocketConnection: (state) => {
      if (state.connection) {
        return state.connection;
      }
      return null;
    },
    getAppSetting: (state) => {
      return state.appSetting;
    },
    getLogger: (state) => state.logger,
    getVideoback: (state) => state.videoBack,
    getBackSpeedFlag: (state) => state.backSpeedFlag,
    getChangeVideoFlag: (state) => state.changeVideoFlag,
    getVideoAllComplete: (state) => state.video_all_complete,
    getLiveAllComplete: (state) => state.live_all_complete,
  },

  mutations: {
    // 更新登录用户
    USER_LOGIN: (state, payload) => {
      window.localStorage.loginUserInfo = JSON.stringify(payload.userInfo);
      state.userInfo = payload.userInfo;
    },
    //互动，评论开启权限
    COMMENT_MSG: (state, payload) => {
      state.msgPermission = payload;
    },
    // 登出
    LOGIN_OUT: (state) => {
      state.userInfo = null;
      state.token = "";
      state.authInfo = null;
      state.connection = null;
      state.certificationVisible = false;
      // state.appSetting = {}
      state.logger = null;
      localStorage.clear();
    },
    // 更新vuex token
    SET_TOKEN: (state, payload) => (state.token = payload.token),
    // token 过期
    TOKEN_EXPIRE: (state) => (state.token = ""),
    // 设置鉴权信息
    SET_AUTH_INFO: (state, payload) => {
      window.localStorage.authInfo = JSON.stringify(payload.authInfo);
      state.authInfo = payload.authInfo;
    },
    // 设置全局socket链接
    SET_GLOBAL_SOCKET: (state, payload) => {
      state.connection = payload.connection;
      state.logger = payload.logger;
    },
    SET_APP_SETTING: (state, payload) => {
      state.appSetting = payload.appSetting;
    },
    SET_ROUTES_MAP: (state, payload) => {
      state.routesMap = payload.routesMap;
    },
    /* tabs选项卡切换，后退保留当前页*/
    SET_TABSNAME: (state, payload) => {
      state.tabsName = payload;
    },
    SET_VIDEOBACK: (state, payload) => {
      state.videoBack = payload;
    },
    SET_VIDEOTIMES: (state, payload) => {
      state.videoTimes = payload;
    },
    SET_BACK_SPEED_FLAG: (state, payload) => {
      state.backSpeedFlag = payload;
    },
    SET_CHANGE_VIDEO_FLAG: (state, payload) => {
      state.changeVideoFlag = payload
    },
    SET_VIDEO_ALL_COMPLETE: (state, payload) => {
      state.video_all_complete = payload
    },
    SET_LIVE_ALL_COMPLETE: (state, payload) => {
      state.live_all_complete = payload
    },
    SET_TASK_NODES: (state, payload) => {
      state.taskNodes = payload
    },
    SET_UNDERWAY_NODE: (state, payload) => {
      state.underwayNode = payload
    },
    SET_COMPLETE_UNDERWAY_NODE: (state, payload) => {
      state.completeUnderwayNode = payload
    },
    SET_NOW_COMPLETE_NODE: (state, payload) => {
      state.nowCompleteNode = payload
    }
  },

  actions: {
    // 异步从缓存更新user
    updateUserInfo(context) {
      if (typeof window.localStorage.loginUserInfo === "string") {
        const userInfo = JSON.parse(window.localStorage.loginUserInfo);
        if (userInfo) {
          context.commit("USER_LOGIN", { userInfo });
        }
      }
      if (typeof window.localStorage.authInfo === "string") {
        const authInfo = JSON.parse(window.localStorage.authInfo);
        if (authInfo) {
          context.commit("SET_AUTH_INFO", { authInfo });
        }
      }
    },
    // 异步读取token
    fetchToken(context) {
      const token = window.localStorage.access_token;
      if (token) {
        context.commit("SET_TOKEN", { token });
      }
    },

    fetchSocketToken(context) {
      if (localStorage._socket_) {
        context.commit("SET_GLOBAL_SOCKET", {
          connection: new SocketUtils().getConnection(),
          logger: new LoggerUtils(),
        });
      }
    },

    // 更新app设置
    async fetchAppSetting(context) {
      // if (typeof window.localStorage._setting_ === "string") {
      //     const appSetting = JSON.parse(window.localStorage._setting_);
      //     if (appSetting) {
      //         context.commit("SET_APP_SETTING", {appSetting});
      //     }
      //     return
      // }
      const { data } = await getAppSetting();
      context.commit("SET_APP_SETTING", { appSetting: data });
    },
  },
});

export default store;
