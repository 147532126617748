<template>
  <div class="footer-wrapper">
    <div class="footer-content">
      <!--顶部-->
      <div class="footer-top">
        <div>
          <img :src="LogoWhiteIcon" alt="" />
        </div>
        <div class="about-us ul-list">
          <div class="title">关于我们</div>
          <ul class="list">
            <li
              class="item"
              v-for="item in aboutListsData"
              :key="item.id"
              @click="dialogVisible(item)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="contract-us ul-list">
          <div class="title">联系我们</div>
          <ul class="list">
            <li class="item" @click="$router.push('/users/index?path=fb')">
              问题反馈
            </li>
            <li class="item">客服电话：{{ appSetting.service_phone }}</li>
            <li class="item">公司地址：{{ appSetting.company_address }}</li>
          </ul>
        </div>
        <div class="other-platforms">
<!--          <div class="platform">-->
<!--            <img-->
<!--              class="qrcode"-->
<!--              :src="appSetting.wecwhat_official_account"-->
<!--              alt="二维码"-->
<!--            />-->
<!--            <div class="title">关注公众号</div>-->
<!--          </div>-->
          <div v-show="appSetting.sidebar_img1" class='platform'>
            <img
              class="qrcode"
              :src="appSetting.sidebar_img1"
              alt=""
            />
            <div class="title">{{appSetting.sidebar_title1}}</div>-->
          </div>
          <div v-show="appSetting.sidebar_img2" class='platform'>
            <img
              class="qrcode"
              :src="appSetting.sidebar_img2"
              alt=""
            />
            <div class="title">{{appSetting.sidebar_title2}}</div>-->
          </div>
          <div v-show="appSetting.sidebar_img3" class='platform'>
            <img
              class="qrcode"
              :src="appSetting.sidebar_img3"
              alt=""
            />
            <div class="title">{{appSetting.sidebar_title3}}</div>-->
          </div>
<!--     TODO: 添加更多可配置项     -->
        </div>
      </div>

      <!--站点信息-->
      <div class="site-info">
        <!--外部链接-->
        <div class="outer-links" v-if="isIndex">
          <div class="title">友情链接</div>
          <p
            class="link"
            v-for="item in friendLinkListsData"
            @click="openLink(item.url)"
            :key="item.id"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="item" v-html="appSetting.copyright"></div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialogVisibleShow"
    :title="visibleTitle"
    width="30%"
    destroy-on-close
  >
    <div v-html="visibleContent" class="visibleContent"></div>
  </el-dialog>
</template>
<script setup>
import { footerData, siteInfo, outerLinks } from "../../assets/js/config";
import { aboutLists, friendLinkLists } from "../../api/common_api";
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";
const aboutListsData = ref(null);
const friendLinkListsData = ref(null);
const visibleContent = ref(null);
const visibleTitle = ref(null);
const dialogVisibleShow = ref(false);
const LogoWhiteIcon = new URL(
  `../../assets/img/common/logo-white.png`,
  import.meta.url
).href;
const store = useStore();
onMounted(async () => {
  const resQueue = await Promise.all([aboutLists(), friendLinkLists()]);
  aboutListsData.value = resQueue[0].data;
  friendLinkListsData.value = resQueue[1].data;
});

const dialogVisible = (item) => {
  const { title, content, url } = item;
  if (url) {
    window.location.href = url;
    return;
  }
  dialogVisibleShow.value = true;
  visibleContent.value = content;
  visibleTitle.value = title;
};

const route = useRoute();
const isIndex = ref(false);
watch(route, (to) => {
  isIndex.value = to.path.startsWith("/index");
});
const appSetting = computed(() => store.getters.getAppSetting);
const openLink = (link) => {
  if (!!link) {
    window.open(link);
  }
};
</script>
<style lang="less" scoped>
.footer-wrapper {
  width: 100%;
  background: #061235;
  box-sizing: border-box;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer-content {
    width: 1200px;
    box-sizing: border-box;

    .footer-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;

      .ul-list {
        .title {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }

        .list {
          margin-top: 20px;

          .item {
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.7);

            &:hover {
              color: rgba(255, 255, 255, 1);
            }

            span {
              margin-right: 4px;
              font-size: 14px;
            }
          }
        }
      }

      .about-us {
        color: #ffffff;
        max-width: 120px;
        max-height: 200px;
        overflow: hidden;
        .list {
          display: grid;
          grid-template-rows: repeat(4, 1fr);
          grid-gap: 14px;
        }
      }

      .contract-us {
        color: #ffffff;
        max-width: 400px;
        max-height: 200px;
        overflow: hidden;
        .list {
          display: grid;
          grid-template-rows: repeat(3, 1fr);
          grid-gap: 14px;
        }
      }

      .other-platforms {
        width: 400px;
        display: flex;
        justify-content: flex-end;

        .platform {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          flex: 0.33;
          flex-shrink: 0;

          .title {
            font-weight: 400;
            color: #ffffff;
            margin-top: 16px;
            text-align: center;
            font-size: 16px;
          }

          img {
            width: 114px;
            height: 114px;
            object-fit: contain;
            border: 1px solid white;
            border-radius: 6px;
          }
        }
      }
    }

    .site-info {
      width: 100%;
      //height: 150px;
      margin-top: 39px;
      border-top: 1px solid rgba(221, 221, 221, 0.6);
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      font-weight: 500;
      box-sizing: border-box;
      padding-top: 40px;
      padding-bottom: 30px;

      .item {
        padding-bottom: 5px;
        color: rgba(255, 255, 255, 0.7);
        ::v-deep(a) {
          cursor: pointer;
          color: rgba(255, 255, 255, 0.7);
          text-decoration: underline;
        }
      }
    }

    .outer-links {
      //padding: 18px 80px 24px 80px;
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .title {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        margin-right: 48px;
      }

      .link {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        margin-right: 36px;
        cursor: pointer;
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
        span {
          font-size: 14px;
          margin-right: 4px;
        }
      }
    }
  }
}

.visibleContent {
  img {
    object-fit: cover;
  }
}
</style>
