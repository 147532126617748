// 子路由模块
import userRoutes from "./modules/userRoutes";
import liveRoutes from "./modules/liveRoutes";
import integralRoutes from "./modules/integralRoutes";
import caseRoutes from "./modules/caseRoutes";
import guidelinesRoutes from "./modules/guidelinesRoutes";
import expertsRoutes from "./modules/expertsRoutes";
import regulationsRoutes from "./modules/regulationsRoutes";
import questionRoutes from "./modules/questionRoutes";
import spaRoutes from "./modules/spaRoutes";
import coursesRoutes from "./modules/coursesRoutes";

// 导出全局routes
export const routes = [{
    path: '/',
    redirect: '/index'
},
    {
        path: "/index",
        component: () =>
            import ("@pages/tabs/index.vue"),
        meta: {
            transition: "slide",
        },
    },
    {
        path: "/course",
        component: () =>
            import ("@pages/tabs/course.vue"),
        meta: {
            transition: "slide",
        },
    },
    {
        path: "/live",
        component: () =>
            import ("@pages/tabs/live.vue"),
        meta: {
            transition: "slide",
        },
    },
    {
        path: "/index/search",
        component: () =>
            import ("@pages/search/index.vue"),
        meta: {
            transition: "slide"
        },
    },
    {
        path: '/pic-live',
        component: () =>
            import ('@pages/live/pic-live.vue'),
        meta: {
            transition: "slide"
        },
    },
    userRoutes,
    liveRoutes,
    integralRoutes,
    caseRoutes,
    guidelinesRoutes,
    expertsRoutes,
    regulationsRoutes,
    questionRoutes,
    spaRoutes,
    coursesRoutes,
    {path: '/:pathMatch(.*)*', name: 'not-found', component: () => import("@pages/common/404.vue")},
];