export default function useValidator() {
  // 身份证验证
  const idValid = (val) => {
    const p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
    const code = val.substring(17);
    if (p.test(val)) {
      let sum = 0;
      for (let i = 0; i < 17; i++) {
        sum += val[i] * factor[i];
      }
      if (parity[sum % 11] == code.toUpperCase()) {
        return ''
      }
    }
    return '请填写正确的身份证号';
  }

  // 手机号验证
  const phoneValid = (val) => {
    return /^1\d{10}$/.test(val) ? '' : '请输入正确的手机号'
  }

  const chineseNameValid = val => {
    return /^(([a-zA-Z+\.?\·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\·?\u4e00-\u9fa5+]{2,10}$))/.test(val) ? '' : '请输入正确的姓名'
  }

  const validMaps = new Map<String, Function>([
    // ["id_card", idValid],
    ["phone", phoneValid],
    ["name", chineseNameValid]
  ])

  return {
    validMaps
  }
}