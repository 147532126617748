import {ElMessage} from "element-plus";

import io from "socket.io-client";
import {refreshSocketToken} from "@api/auth_api";
import store from "../store";
/**
 * <p>此处因为后端原因，仅支持2.4.0版本的客户端，后续若需要升级，更新npm包即可
 * import io from 'socket.io-client' @2.4.0
 * import {io} from 'socket.io-client' @4.0 +
 * 基本socket链接类
 * </p>
 * @author wanglu
 */
import VueEvent from "./global_event";
export default class WSUtils {
    connection = null;
    retryCount = 0;
    alertReconnecting = false;

    constructor() {
        this.initConnection();
    }

    handleReconnect = () => {
        const socketToken = !!localStorage._socket_;
        if (!socketToken) {
            this.releaseConnection();
        }
    };

    // 更新socket的token
    updateSocketToken = async () => {
        const {data} = await refreshSocketToken();
        const {socket_token} = data;
        localStorage._socket_ = socket_token;
        this.initConnection();
    };

    bindEvent = () => {
        this.connection.on("disconnect", (e) => {
            this.handleReconnect();
        });
        this.connection.on("connect_error", (err) => {
            this.handleReconnect();
        });
        this.connection.on("reconnecting", async (e) => {
            // 重连三次
            if (this.retryCount < 3) {
                this.retryCount++;
            } else {
                if (!this.alertReconnecting) {
                    ElMessage.info("正常重连中，请稍候");
                    this.alertReconnecting = true;
                }
                this.releaseConnection();
                await this.updateSocketToken();
            }
        });
        this.connection.on("reconnect", (e) => {
            ElMessage.info("重连成功，请稍候...");
            this.handleConnectEvent();
        });
        this.connection.on("connect", (evt) => {
            this.handleConnectEvent();
        });

        this.connection.on("logout", (evt) => {
            ElMessage.warning("提示：" + evt.msg)
            store.commit('LOGIN_OUT')
            this.releaseConnection();
        });
    };

    handleConnectEvent = () => {
        VueEvent.emit("socketReconnect");
        this.retryCount = 0;
        this.alertReconnecting = false;
    };

    // 初始化链接
    initConnection = () => {
        try {
            if (this.connection) {
                this.releaseConnection();
            }
            this.connection = io(import.meta.env.VITE_APP_SOCKET_URL, {
                query: localStorage._socket_ + '&platform=5',
                transports: ["websocket"],
            });
            this.bindEvent();
        } catch (error) {
            this.connection = null;
        }
    };

    getConnection() {
        if (this.connection) {
            return this.connection;
        }
        this.initConnection();
        return this.connection;
    }

    // 释放链接
    releaseConnection() {
        if (this.connection) {
            this.connection.disconnect();
            this.connection.close();
            this.connection = null;
        }
    }
}
