import request from '../utils/request'

const prefix = '/user'

// 获取个人资料
export function profile() {
    return request.get(`/user/profile`, {})
}

// 修改个人资料
export function editProfile(params) {
	return request.post(`/user/register-profile`, params)
}

// 实名认证
export function auth() {
    return request.get(`/user/auth`, {})
}

// 提交实名认证
export function getUserAuth(params) {
    return request.post(`/user/auth`, params)
}

/**
 * 提交身份认证
 * @param {
 * company_name , profession , passport_id , hospital_id , depart_id , job , passport_photo_face
 * } params
 * @returns
 */
export function getUserProfession(params) {
    return request.post(`/user/modify-profession`, params)
}

//修改头像
export function editAvatar(params) {
    return request.post(`/user/avatar`, params)
}

//修改手机号
export function phone(params) {
    return request.post(`/user/phone`, params)
}

// 获取收货地址列表
export function getAddressList() {
    return request.get(`/user/address`, {})
}

// 保存修改地址
export function addAddress(params) {
    return request.post(`/user/address`, params)
}

// 获取收货地址
export function addressDetail(id) {
    return request.get(`/user/address/${id}`)
}

// 设为默认地址
export function setDefault(params) {
    return request.post(`/user/address/setDefault`, params)
}

// 删除地址
export function deleteAddress(id) {
    return request.post(`/user/address/delete`, { id: id })
}

// 我的反馈
export function lists(params) {
    return request.get(`/feedback/lists`, { params })
}

// 提交反馈
export function feedbackAdd(params) {
    return request.post(`/feedback/add`, params)
}

// 我的关注
export function follows(params) {
    return request.get(`/doctor/follows`, { params })
}

// 我的学习记录
export function learns(params) {
    return request.get(`/learns`, { params })
}

// 删除学习记录
export function delLearns(id) {
    return request.delete(`/learn/${id}`)
}

// 积分明细
export function transactions(params) {
    return request.get(`/point/transactions`, { params })
}

// 商品列表
export function goods(params) {
    return request.get(`/shop/goods`, { params })
}

// 商品详情
export function goodsDetail(id) {
    return request.get(`/shop/goods/${id}`)
}

// 我的收藏列表
export function favorites(params) {
    return request.get(`/favorites`, { params })
}

// 我的收藏删除新增
export function favoritesType(params) {
    return request.post(`/favorites`, params)
}

// 政策法规详情
export function show(id) {
    return request.get(`/news/show/${id}`)
}

// 获取评论
export function comments(params) {
    return request.get(`/comments`, params)
}

// 积分订单列表
export function orders(params) {
    return request.get(`/shop/orders`, { params })
}

// 积分订单详情
export function ordersInfo(id) {
    return request.get(`/shop/orders/${id}`, {})
}

// 订单删除
export function ordersDel(params) {
    return request.post(`/shop/orders/delete`, params)
}

// 确认收货
export function ordersSucceeded(params) {
    return request.post(`/shop/orders/succeeded`, params)
}

// 金币订单列表
export function courses(params) {
    return request.get(`/money/orders`, { params })
}

// 消息列表
export function notifications(params) {
    return request.get(`/notifications`, { params })
}

// 未读消息列表
export function unreadNotifications(params) {
  return request.get(`/notifications/unread`, { params })
}

// 指定消息为已读
export function read(id) {
    return request.post(`/notifications/mark-read`, id)
}

// 所有消息为已读
export function allRead() {
  return request.post(`/notifications/mark-all-read`, {})
}

// 消息设置
export function setUp() {
  return request.post(`/notifications`, {})
}
