// 初始化阿里 logger 工具类
import SlsWebLogger from "js-sls-logger";
import store from "../store/index";

// 生成uuid
function guid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getCurrentNetworkType() {
  var ua = navigator.userAgent;
  var networkStr = ua.match(/NetType\/\w+/)
    ? ua.match(/NetType\/\w+/)[0]
    : "NetType/other";
  networkStr = networkStr.toLowerCase().replace("nettype/", "");
  return networkStr || "unknown";
}

export default class LoggerUtils {
  uuid = null;
  // 页面跳转
  pageNavigateLogger = null;
  // 课程视频
  courseVideoLogger = null;
  // 直播视频
  liveVideoLogger = null;
  // 页面持续时间
  pageDurationLogger = null;
  // 回放视频
  playbackVideoLogger = null;
  // 任务视频
  taskVideoLogger = null;
  // 广告点击
  adClickLogger = null;
  networkType = null;

  constructor() {
    this.pageNavigateLogger = new SlsWebLogger({
      host: "cn-beijing.log.aliyuncs.com",
      project: "jiyuanyixue",
      logstore: "page_navigate",
      time: 1,
      count: 10,
    });
    this.liveVideoLogger = new SlsWebLogger({
      host: "cn-beijing.log.aliyuncs.com",
      project: "jiyuanyixue",
      logstore: "live_video",
      time: 1,
      count: 10,
    });

    this.pageDurationLogger = new SlsWebLogger({
      host: "cn-beijing.log.aliyuncs.com",
      project: "jiyuanyixue",
      logstore: "page_duration",
      time: 1,
      count: 10,
    });

    this.courseVideoLogger = new SlsWebLogger({
      host: "cn-beijing.log.aliyuncs.com",
      project: "jiyuanyixue",
      logstore: "course_video",
      time: 1,
      count: 10,
    });

    this.playbackVideoLogger = new SlsWebLogger({
      host: "cn-beijing.log.aliyuncs.com",
      project: "jiyuanyixue",
      logstore: "playback_video",
      time: 1,
      count: 10,
    });

    this.taskVideoLogger = new SlsWebLogger({
      host: "cn-beijing.log.aliyuncs.com",
      project: "jiyuanyixue",
      logstore: "task_video",
      time: 1,
      count: 10,
    });

    this.adClickLogger = new SlsWebLogger({
      host: "cn-beijing.log.aliyuncs.com",
      project: "jiyuanyixue",
      logstore: "ad_click",
      time: 0.001,
      count: 1,
    });
    this.videoBackLogger = new SlsWebLogger({
      host: "cn-beijing.log.aliyuncs.com",
      project: "jiyuanyixue",
      logstore: "video_log",
      time: 1,
      count: 10,
    });
    this.getNetworkType();
  }

  // 获取uuid
  getUUID = () => {
    if (this.uuid) {
      return this.uuid;
    }
    const uuid = localStorage._uuid_;
    if (uuid) {
      this.uuid = uuid;
      return uuid;
    }
    this.uuid = guid();
    localStorage._uuid_ = this.uuid;
    return this.uuid;
  };

  getPlatform = () => {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      return "IOS";
    } else if (/(Android)/i.test(navigator.userAgent)) {
      return "Android";
    } else {
      return "PC";
    }
  };

  // 获取用户id
  getUserId = () => {
    const userInfo = store.getters.getUserInfo;
    return userInfo.id || "";
  };

  logBuilder = (extraLog = {}) => {
    return {
      uuid: this.getUUID(),
      network: this.getNetworkType(),
      user_id: this.getUserId(),
      sid: 0, // h5端最多能获取到UA
      cid: 5, // 微信
      platform: this.getPlatform(),
      ...extraLog,
    };
  };

  // 发送日志
  sendLog = (instance, log = {}) => {
    instance.send(this.logBuilder(log));
  };

  getCommonField = () => {
    return this.logBuilder();
  };

  /**
   * 记录页面跳转日志
   * @param {*} origin_page      来源页面
   * @param {*} dist_page        目标页面
   * @param {*} origin_params    来源页面参数
   * @param {*} dist_params      目标页面参数
   */
  pageNavigateEvent(origin_page, dist_page, origin_params, dist_params) {
    this.sendLog(this.pageNavigateLogger, {
      origin_page,
      dist_page,
      origin_params,
      dist_params,
    });
    const connection = store.getters.getSocketConnection;
    if (connection) {
      const commonField = this.getCommonField();
      connection.emit("log", {
        event: "page_navigate",
        ...commonField,
        origin_page,
        origin_params,
        dist_page,
        dist_params,
        url: dist_page,
        params: dist_params,
      });
    }
  }

  /**
   * 记录页面停留时间
   * @param {*} page_path 页面地址
   * @param {*} page_duration 停留时间
   * @param {*} page_params 页面参数
   * @param {*} record_uid 记录uid
   */
  pageDurationEvent(page_path, page_duration, page_params, record_uid) {
    this.sendLog(this.pageDurationLogger, {
      // page_id,
      page_path,
      page_duration,
      page_params,
      record_uid,
    });
    const connection = store.getters.getSocketConnection;
    if (connection) {
      const commonField = this.getCommonField();
      connection.emit("log", {
        event: "page_duration",
        ...commonField,
        // page_id,
        page_path,
        page_duration,
        page_params,
        record_uid,
      });
    }
  }

  /**
   * 记录直播视频播放
   * @param {*} meeting_id         会议id
   * @param canplay           观看状态 1 点击了播放按钮  null 正常播放
   * @param id                 后台记录时长
   * @param place_id              会场id
   */
  liveStreamingEvent(meeting_id, canplay, id, place_id) {
    this.sendLog(this.liveVideoLogger, {
      meeting_id,
    });
    const connection = store.getters.getSocketConnection;
    if (connection) {
      connection.emit("live_log", {
        user_id: this.getUserId(),
        meeting_id,
        canplay,
        id,
        place_id,
      });
    }
  }

  /**
   * 直播回放日志
   * @param {*} meeting_id       会议id
   * @param {*} round_id         会场id
   * @param {*} media_id         视频id
   * @param {*} page_duration        持续播放时长
   * @param media_link
   * @param record_uid
   */
  playbackEvent(
    meeting_id,
    round_id,
    media_id,
    page_duration,
    media_link,
    record_uid
  ) {
    this.sendLog(this.playbackVideoLogger, {
      meeting_id,
      round_id,
      media_id,
      page_duration,
      media_link,
      record_uid,
    });
    const connection = store.getters.getSocketConnection;
    if (connection) {
      const commonField = this.getCommonField();
      connection.emit("log", {
        event: "live_video",
        ...commonField,
        meeting_id,
        round_id,
        media_id,
        page_duration,
        media_link,
        record_uid,
      });
    }
  }

  /**
   * 记录课程播放时间
   * @param {*} course_id      课程id
   * @param {*} media_id       视频id
   * @param {*} duration      时长
   * @param {*} media_link     视频链接
   * @param record_uid
   */
  courseEvent(course_id, media_id, duration, media_link, record_uid) {
    this.sendLog(this.courseVideoLogger, {
      course_id,
      media_id,
      duration,
      media_link,
      record_uid,
    });
  }
  /**
   * 记录回放视频时长
   */
  videoBackEvent(id, duration) {
    const connection = store.getters.getSocketConnection;
    if (connection) {
      connection.emit("video_log", {
        id,
        view_progress: duration,
      });
    }
  }

  /**
   * 课程视频播放日志
   */
  courseVideoEvent(id, duration) {
    const connection = store.getters.getSocketConnection;
    if (connection && id) {
      connection.emit("course_log", {
        id,
        view_progress: duration,
      });
    }
  }
  /**
   * 记录视频任务播放
   * @param {*} task_id      任务id
   * @param {*} media_id     视频id
   * @param {*} duration    时长
   * @param {*} media_link   链接
   * @param record_uid
   */
  taskVideoEvent(task_id, media_id, duration, media_link, record_uid) {
    this.sendLog(this.taskVideoLogger, {
      task_id,
      media_id,
      duration,
      media_link,
      record_uid,
    });
  }

  /**
   * 广告点击事件
   * @param ad_id     广告id
   * @param ad_link   广告链接
   */
  adClickEvent(ad_id, ad_link) {
    this.sendLog(this.adClickLogger, {
      ad_id,
      ad_link,
    });
  }

  // 获取网络状态
  getNetworkType = () => {
    if (this.networkType) {
      return this.networkType;
    }
    this.networkType = getCurrentNetworkType();
  };
}
