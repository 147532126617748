import Layout from "@components/layout/Layout.vue";
// 病例库页面
export default {
    path: "/spa",
    component: Layout,
    meta: {
        transition: "slide",
    },
    children: [
        {
            path: "index",
            component: () => import("@pages/spa/index.vue")
        }
    ],
};
