import Layout from "@components/layout/Layout.vue";
//问卷调查页面
export default {
    path: '/question',
    component: Layout,
    meta: { transition: 'slide' },
    alwaysShow: true,
    children: [
        {
            path: 'index',
            component: () => import('@pages/question/index.vue'),
        },
        {
            path: 'success',
            component: () => import('@pages/question/submit-success.vue'),
        },
        {
            path: 'answer',
            component: () => import('@pages/question/answer.vue'),
        },
    ],
};