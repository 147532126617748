export default class TimeUtils {
    timestamp
    // handle global timeFormat
    // type timestamp | date
    constructor(ts, type = 'timestamp') {
        if (type === 'timestamp') {
            this.timestamp = ts
            return
        }
        if (type === 'date') {
            ts = ts.replace(/-/g, '/')
            this.timestamp = Math.floor(new Date(ts).getTime() / 1000)
            return
        }
        throw new Error("unknown time format")
    }

    // 计算日期
    calcDate() {
        let currentTS = this.timestamp * 1000
        let currentDate = new Date(currentTS)
        let year = currentDate.getFullYear()
        let month = currentDate.getMonth() + 1
        if (month < 10) month = '0' + month
        let date = currentDate.getDate()
        if (date <= 9) date = '0' + date
        return { currentTS, currentDate, year, month, date }
    }

    // get local date time
    getLocalDate() {
        const { year, month, date } = this.calcDate()
        return `${year}.${month}.${date}`
    }

    getLocalDateTime() {
        const { currentDate, year, month, date } = this.calcDate()
        let hour = currentDate.getHours()
        if (hour <= 9) hour = '0' + hour
        let minute = currentDate.getMinutes()
        if (minute <= 9) minute = '0' + minute
        let second = currentDate.getSeconds()
        if (second <= 9) second = '0' + second
        return `${year}.${month}.${date} ${hour}:${minute}:${second}`
    }

    getRemainsTime() {
        let now = Date.now()
        let remains = this.timestamp * 1000 - now
        if (remains < 0) {
            return 0
        }
        let hourM = 3600 * 1000
        let minuteM = 60 * 1000
        let hour = Math.floor(remains / hourM)
        remains = remains - hourM * hour
        let minute = Math.floor(remains / minuteM)
        return `${hour}小时${minute}分钟`
    }

    /**
     * 多久之后
     */
    howSoon() {
        let now = Date.now()
        let remains = this.timestamp * 1000 - now
        const rSeconds = Math.floor((this.timestamp * 1000 - now) / 1000)
        if (remains < 0) {
            return {
                format: 0,
                rSeconds: 0
            }
        }
        const { day, hour, minute, second } = this.calcTimes(remains)
        return {
            format: `${day}天 ${hour}:${minute}:${second}`,
            seconds: rSeconds
        }
    }

    static calcTime(remains) {
        let dayM = 3600 * 24 * 1000
        let hourM = 3600 * 1000
        let minuteM = 60 * 1000
        let secondM = 1000
        let day = Math.floor(remains / dayM)
        remains = remains - dayM * day
        let hour = Math.floor(remains / hourM)
        remains = remains - hourM * hour
        let minute = Math.floor(remains / minuteM)
        remains = remains - minuteM * minute
        let second = Math.floor(remains / secondM)
        if (day < 10) day = '0' + day
        if (hour < 10) hour = '0' + hour
        if (minute < 10) minute = '0' + minute
        if (second < 10) second = '0' + second
        return {
            days: day,
            hours: hour,
            minutes: minute,
            seconds: second
        }
    }

    calcTimes(remains) {
        let dayM = 3600 * 24 * 1000
        let hourM = 3600 * 1000
        let minuteM = 60 * 1000
        let secondM = 1000
        let day = Math.floor(remains / dayM)
        remains = remains - dayM * day
        let hour = Math.floor(remains / hourM)
        remains = remains - hourM * hour
        let minute = Math.floor(remains / minuteM)
        remains = remains - minuteM * minute
        let second = Math.floor(remains / secondM)
        if (day < 10) day = '0' + day
        if (hour < 10) hour = '0' + hour
        if (minute < 10) minute = '0' + minute
        if (second < 10) second = '0' + second
        return {
            days: day,
            hours: hour,
            minutes: minute,
            seconds: second
        }
    }

    /**
     * 多久之后
     */
    getRemainsEachUnit() {
        let now = Date.now()
        let remains = this.timestamp * 1000 - now
        if (remains < 0) {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                remains: Math.floor(remains / 1000)
            }
        }
        return {
            ...this.calcTimes(remains),
            remains: Math.floor(remains / 1000)
        }
    }
}