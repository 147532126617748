<template>
  <div class="flex-center">
    <div class="label">
      {{ item.title }}<span v-if="parseInt(item.required) === 1">*</span>：
    </div>
    <div v-if="item" class="multi-select-field">
      <el-checkbox-group
        v-model="value"
        :disabled="edit"
        text-color="#0398FE"
        @change="checkboxGroupChange"
      >
        <el-checkbox
          v-for="(item, index) in list"
          :label="item.name"
          :key="index"
          >{{ item.name }}</el-checkbox
        >
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkbox-field",
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {
        return null;
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      value: "",
    };
  },
  methods: {
    // 选中某个单选框时，由radio时触发
    checkboxGroupChange(e) {
      const { item } = this;
      item.value = e;
      item.error = !!item.value ? "" : "请至少选择一项";
      this.$emit("updateField", {
        index: this.currentIndex,
        item,
      });
    },
  },
  mounted() {
    const { options, value } = this.item;
    this.list = options.map((option) => {
      return {
        name: option,
        checked: false,
      };
    });
    if (value === "") {
      this.value = [];
    }
    if (Array.isArray(value)) {
      this.value = value;
    }
  },
};
</script>

<style lang="less" scoped>
.flex-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multi-select-field {
  width: 364px;
  ::v-deep(.el-checkbox-group) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.label {
  font-size: 14px;
  width: 100px;
  font-weight: 400;
  color: #111111;
  text-align: right;
  span {
    color: #f56c6c;
  }
}
</style>
