<template>
  <div>
    <div class="identity">
      <i class="el-icon-close identity-close" @click="closeVisible"></i>
      <div class="cer-content">
        <h3>{{ isEdit ? "变更身份认证信息" : "身份认证" }}</h3>
        <div class="from-content" v-loading="loading">
          <p class="from-notice">为了给您提供更好的服务，请先完成认证</p>
          <div>
            <el-form :model="userData" label-width="110px" class="headForm">
              <el-form-item label="姓名">
                <span v-if="userData">{{ userData.name }}</span>
              </el-form-item>
              <el-form-item label="您的身份">
                <el-select
                  v-model="userData.user_type"
                  placeholder="请选择"
                  style="width: 50%"
                  @change="checkType('user_type', userData.user_type)"
                >
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in typeList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-form
              :model="doctorForm"
              :popper-append-to-body="false"
              :rules="rules"
              ref="doctorForm"
              label-width="110px"
              class="doctorForm"
              v-if="userData && parseInt(userData.user_type) === 1"
            >
              <el-form-item label="职业" prop="profession_id">
                <el-select
                  v-model="doctorForm.profession"
                  placeholder="请选择职业"
                  style="width: 100%"
                  @change="checkType('profession', doctorForm.profession)"
                >
                  <el-option
                    :label="item.name"
                    :value="{ value: item.id, label: item.name }"
                    v-for="item in occupationList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="职称" prop="job_title_id">
                <el-select
                  v-model="doctorForm.job_title"
                  placeholder="请选择职称"
                  style="width: 100%"
                  @click="noticeFun('job_title', doctorForm.profession)"
                  :disabled="!doctorForm.profession"
                  @change="checkType('job_title', doctorForm.job_title)"
                >
                  <el-option
                    :label="item.name"
                    :value="{ value: item.id, label: item.name }"
                    v-for="item in technicalList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="就职医院" prop="company_name">
                <el-autocomplete
                  v-model="doctorForm.company_name"
                  :fetch-suggestions="selectCompany"
                  placeholder="请输入医院名称"
                  @select="handleSelect"
                  :highlight-first-item="false"
                  style="width: 100%"
                  :trigger-on-focus="false"
                >
                  <template #default="{ item }">
                    <div class="name">{{ item.name }}</div>
                  </template>
                </el-autocomplete>
              </el-form-item>
              <el-form-item label="所属科室" prop="depart">
                <el-cascader
                  v-model="doctorForm.depart"
                  style="width: 100%"
                  :options="departList"
                  placeholder="请选择科室"
                  :props="{
                    value: 'id',
                    label: 'name',
                    expandTrigger: 'hover',
                  }"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="就任职务" prop="job_id">
                <el-select
                  v-model="doctorForm.job"
                  placeholder="请选择职务"
                  style="width: 100%"
                  :disabled="!doctorForm.job_title"
                  @click="noticeFun('job', doctorForm.job_title)"
                  @change="checkType('job', doctorForm.job)"
                >
                  <el-option
                    :label="item.name"
                    :value="{ value: item.id, label: item.name }"
                    v-for="item in postList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <div class="prompt" style="position: relative; top: -9px">
                  <i
                    class="el-icon-warning-outline"
                    style="color: #e57922; margin-right: 4px"
                  ></i>
                  <p>
                    根据《中华人民共和国网络安全法》及其他行业要求，需要您完成卫生技术职业人员认证，敬请谅解。
                  </p>
                </div>
              </el-form-item>
              <p class="id-head">下属两项填写其中一项即可</p>
              <el-form-item label="执业证书ID" prop="passport_id">
                <el-input
                  v-model="doctorForm.passport_id"
                  placeholder="请输入证书ID"
                ></el-input>
              </el-form-item>
              <el-form-item label="上传身份证明">
                <div class="upload-block">
                  <el-upload
                    class="avatar-uploader"
                    :action="action"
                    :headers="headers"
                    :show-file-list="false"
                    :on-success="backAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      v-if="backImageUrl"
                      :src="backImageUrl"
                      class="avatar"
                    />
                    <div v-else class="up-con">
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                      <div>请上传执业证书编码页</div>
                    </div>
                  </el-upload>
                  <el-upload
                      class="avatar-uploader"
                      :action="action"
                      :headers="headers"
                      :show-file-list="false"
                      :on-success="headAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                  >
                    <img
                        v-if="headImageUrl"
                        :src="headImageUrl"
                        class="avatar"
                    />
                    <div v-else class="up-con">
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                      <div>请上传个人基本信息页</div>
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
              <el-form-item
                class="submit"
                :class="{
                  'submit-button':
                    (doctorForm.profession &&
                      doctorForm.job_title &&
                      doctorForm.company_name &&
                      doctorForm.depart &&
                      doctorForm.job &&
                      doctorForm.passport_id) ||
                    (doctorForm.profession &&
                      doctorForm.job_title &&
                      doctorForm.company_name &&
                      doctorForm.depart &&
                      doctorForm.job &&
                      doctorForm.passport_photo_face &&
                      doctorForm.passport_photo),
                }"
              >
                <el-button
                  type="primary"
                  @click="submitDoctorForm('doctorForm')"
                  >提交
                </el-button>
                <p class="jump">
                  <span @click="closeVisible">跳过</span>
                </p>
              </el-form-item>
            </el-form>
            <el-form
              :model="staffForm"
              :rules="rules"
              ref="staffForm"
              label-width="110px"
              class="demo-staffForm"
              v-if="userData && parseInt(userData.user_type) === 2"
            >
              <el-form-item label="职业" prop="profession_id">
                <el-select
                  v-model="staffForm.profession"
                  placeholder="请选择职业"
                  style="width: 100%"
                  @change="checkType('profession', staffForm.profession)"
                >
                  <el-option
                    :label="item.name"
                    :value="{ value: item.id, label: item.name }"
                    v-for="item in occupationList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="就职单位" prop="company_name">
                <el-autocomplete
                  v-model="staffForm.company_name"
                  :fetch-suggestions="selectCompany"
                  placeholder="请输入就职单位"
                  @select="handleSelect"
                  :highlight-first-item="false"
                  style="width: 100%"
                  :trigger-on-focus="false"
                >
                  <template #default="{ item }">
                    <div class="name">{{ item.company_name }}</div>
                  </template>
                </el-autocomplete>
              </el-form-item>
              <el-form-item label="就任职务" prop="job_id">
                <el-select
                  v-model="staffForm.job"
                  placeholder="请选择职务"
                  style="width: 100%"
                  :disabled="!staffForm.profession"
                  @click="noticeFun('job', staffForm.profession)"
                  @change="checkType('job', staffForm.job)"
                >
                  <el-option
                    :label="item.name"
                    :value="{ value: item.id, label: item.name }"
                    v-for="item in postList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="submit"
                :class="{
                  'submit-button':
                    staffForm.profession &&
                    staffForm.job &&
                    staffForm.company_name,
                }"
              >
                <el-button type="primary" @click="submitStaffForm('staffForm')"
                  >提交
                </el-button>
                <p class="jump">
                  <span @click="closeVisible">跳过</span>
                </p>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="auditFailed" v-if="auditFailed">
          <div class="close" @click="auditFailed = false">
            <svg
              t="1637989426404"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2438"
              width="25"
              height="25"
            >
              <path
                d="M265.28 310.72a32 32 0 0 1 45.44-45.44l448 448a32 32 0 0 1-45.44 45.44z"
                p-id="2439"
                fill="#8a8a8a"
              ></path>
              <path
                d="M713.28 265.28a32 32 0 0 1 45.44 45.44l-448 448a32 32 0 0 1-45.44-45.44z"
                p-id="2440"
                fill="#8a8a8a"
              ></path>
            </svg>
          </div>
          <div class="title">
            <svg
              t="1637985096534"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2291"
              width="40"
              height="40"
            >
              <path
                d="M512 981.333333C252.8 981.333333 42.666667 771.2 42.666667 512S252.8 42.666667 512 42.666667s469.333333 210.133333 469.333333 469.333333-210.133333 469.333333-469.333333 469.333333z m44.245333-469.333333l159.914667-159.914667a31.274667 31.274667 0 1 0-44.245333-44.245333L512 467.754667 352.085333 307.84a31.274667 31.274667 0 1 0-44.245333 44.245333L467.754667 512l-159.914667 159.914667a31.274667 31.274667 0 1 0 44.245333 44.245333L512 556.245333l159.914667 159.914667a31.274667 31.274667 0 1 0 44.245333-44.245333L556.245333 512z"
                fill="#F5222D"
                p-id="2292"
              ></path>
            </svg>
            <div>您的身份认证信息审核不通过，请重新提交</div>
          </div>
          <p>审核失败原因：</p>
          <p>{{ reason }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mask" @click="closeVisible"></div>
</template>

<script>
import { getCommonDictionary, getDepartment } from "@/api/common_api.js";
import { getUserProfession, profile, auth } from "@/api/personal_api.js";
import { getCompanySearch, getHospitalSearch } from "@/api/search_api.js";
import VueEvent from "../../utils/global_event";
import { ElMessage } from "element-plus";

export default {
  name: "Identity",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reason: null,
      loading: true,
      auditFailed: false,
      typeList: [
        {
          id: 0,
          name: "请选择",
        },

        {
          id: 1,
          name: "医护人员",
        },
        {
          id: 2,
          name: "其他",
        },
      ],
      doctorForm: {},
      staffForm: {},
      headImageUrl: null,
      backImageUrl: null,
      userData: {}, // 提交身份认证信息
      departList: [],
      occupationList: null, //职业
      technicalList: null, //职称
      postList: null, //职务
      companyList: null, //医院||公司列表
      action: null,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      rules: {
        profession_id: [
          { required: true, message: "请选择职业", trigger: "change" },
        ],
        job_title_id: [
          { required: true, message: "请选择职称", trigger: "change" },
        ],
        company_name: [
          { required: true, message: "请填写所属单位", trigger: "blur" },
        ],
        depart: [
          { required: true, message: "请选择所属科室", trigger: "change" },
        ],
        job_id: [{ required: true, message: "请选择职务", trigger: "change" }],
      },
      userPhone: "", // 用户手机号
    };
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
    },
    async getCommon(id) {
      let { data } = await getCommonDictionary(id);
      switch (id) {
        case 2:
        case 11:
          this.occupationList = data;
          break;
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
          this.technicalList = data;
          break;
        case 9:
        case 12:
          this.postList = data;
          break;
        default:
          break;
      }
    },
    // 所有下拉框联动
    checkType(type, val) {
      switch (type) {
        case "user_type":
          this.doctorForm = {};
          this.staffForm = {};
          this.userData.user_type = val;
          this.headImageUrl = null;
          this.backImageUrl = null;
          if (parseInt(val) === 1) {
            this.getCommon(2);
          } else {
            this.getCommon(11);
          }
          break;
        case "profession":
          this.technicalFun(val.value);
          if (parseInt(this.userData.user_type) === 1) {
            this.doctorForm.profession_id = val.value;
            this.doctorForm.profession = val.label;
            this.doctorForm.job_title_id = null;
            this.doctorForm.job_title = null;
            this.doctorForm.job_id = null;
            this.doctorForm.job = null;
          } else {
            this.staffForm.profession_id = val.value;
            this.staffForm.profession = val.label;
            this.staffForm.job_title_id = null;
            this.staffForm.job_title = null;
            this.staffForm.job_id = null;
            this.staffForm.job = null;
          }
          break;
        case "job_title":
          this.getCommon(9);
          if (parseInt(this.userData.user_type) === 1) {
            this.doctorForm.job_title_id = val.value;
            this.doctorForm.job_title = val.label;
            this.doctorForm.job_id = null;
            this.doctorForm.job = null;
          } else {
            this.staffForm.job_title_id = val.value;
            this.staffForm.job_title = val.label;
            this.staffForm.job_id = null;
            this.staffForm.job = null;
          }
          break;
        case "job":
          if (parseInt(this.userData.user_type) === 1) {
            this.doctorForm.job_id = val.value;
            this.doctorForm.job = val.label;
          } else {
            this.staffForm.job_id = val.value;
            this.staffForm.job = val.label;
          }
          break;

        default:
          break;
      }
    },
    // 根据选择类型或职业职称调用函数
    // 3医生职称，4护士职称，5药师职称，6技师职称，7治疗师职称，8医疗协会职称，
    technicalFun(id) {
      switch (id) {
        case 15:
          this.getCommon(3);
          break;
        case 16:
          this.getCommon(4);
          break;
        case 17:
          this.getCommon(5);
          break;
        case 18:
          this.getCommon(6);
          break;
        case 19:
          this.getCommon(7);
          break;
        case 20:
          this.getCommon(8);
          break;
        default:
          this.getCommon(12);
          break;
      }
    },
    // 头像照片回调
    headAvatarSuccess(res, file) {
      this.headImageUrl = URL.createObjectURL(file.raw);
      this.doctorForm.passport_photo_face = res.data.url;
    },
    //背面照片回调
    backAvatarSuccess(res, file) {
      this.backImageUrl = URL.createObjectURL(file.raw);
      this.doctorForm.passport_photo = res.data.url;
    },
    // 选择职称职务时提示
    noticeFun(type, val) {
      switch (type) {
        case "job_title":
          if (!val) ElMessage.warning("请先选择职业");
          break;
        case "job":
          if (!val && this.userData.user_type === 1)
            ElMessage.warning("请先选择职称");
          if (!val && this.userData.user_type === 2)
            ElMessage.warning("请先选择职业");
          break;
        default:
          break;
      }
    },
    // 弹窗关闭
    closeVisible() {
      VueEvent.emit("triggerIdentity", false);
      this.getAuth();
    },
    async getAuth() {
      const authInfo = await auth();
      this.$store.commit("SET_AUTH_INFO", {
        authInfo: authInfo.data,
      });
    },
    // 根据类型和名称搜索医院
    async selectCompany(companyName, closeList) {
      try {
        let res;
        if (parseInt(this.userData.user_type) === 1) {
          res = await getHospitalSearch({ word: companyName });
        } else {
          res = await getCompanySearch({ word: companyName });
        }
        if (res.data) {
          let results = res.data;
          let resultsList = companyName
            ? results.filter(this.createStateFilter(companyName))
            : results;
          if (resultsList) {
            closeList(resultsList);
          }
        }
      } catch (error) {
        ElMessage.error(error);
      }
    },
    createStateFilter(companyName) {
      if (parseInt(this.userData.user_type) === 1) {
        return (restaurant) => {
          return (
            restaurant.name.toLowerCase().indexOf(companyName.toLowerCase()) ||
            restaurant.name.toLowerCase().indexOf(companyName.toLowerCase()) ===
              0
          );
        };
      } else {
        return (restaurant) => {
          return (
            restaurant.company_name
              .toLowerCase()
              .indexOf(companyName.toLowerCase()) ||
            restaurant.company_name
              .toLowerCase()
              .indexOf(companyName.toLowerCase()) === 0
          );
        };
      }
    },
    // 医院和职业选择列表中某一项时
    handleSelect(item) {
      if (parseInt(this.userData.user_type) === 1) {
        this.doctorForm.company_name = item.name;
        this.doctorForm.company_id = item.id;
      } else {
        this.staffForm.company_name = item.company_name;
        this.staffForm.company_id = item.id;
      }
    },
    // 最终提交   ------医生
    submitDoctorForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let form = {
            ...this.doctorForm,
            user_type: this.userData.user_type,
            hospital_name: this.doctorForm.company_name,
            hospital_id: this.doctorForm.company_id,
            depart_id: this.doctorForm.depart[0],
            depart_ids: this.doctorForm.depart[1],
            phone: this.userPhone,
            // ...this.doctorForm,
          };
          if (!form.hospital_id) {
            ElMessage.error("请输入您的医院名称，并从下拉框选择");
            return false;
          }
          if (
            !form.passport_id &&
            !form.passport_photo_face &&
            !form.passport_photo
          ) {
            ElMessage.warning("请填写执业证书编号或者上传执业证书图片");
            return false;
          }
          if (
            !form.passport_id &&
            form.passport_photo_face &&
            !form.passport_photo
          ) {
            ElMessage.warning("请上传完整的执业证书图片");
            return false;
          }
          if (
            !form.passport_id &&
            !form.passport_photo_face &&
            form.passport_photo
          ) {
            ElMessage.warning("请上传完整的执业证书图片");
            return false;
          }
          try {
            const { msg } = await getUserProfession(form);
            ElMessage.success(msg);
            const user = await profile();
            this.getAuth();
            this.$store.commit("USER_LOGIN", {
              userInfo: user.data,
            });
            VueEvent.emit("triggerIdentity", false);
            VueEvent.emit("identitySuccess");
          } catch (e) {
            ElMessage.error(e);
          }
        } else {
          return false;
        }
      });
    },
    // 最终提交   ------其他
    submitStaffForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        try {
          if (valid) {
            let form = {
              ...this.staffForm,
              user_type: this.userData.user_type,
              phone: this.userPhone,
              // ...this.staffForm,
            };
            const { msg, errorCode } = await getUserProfession(form);
            if (errorCode !== 0) {
              ElMessage.error(msg);
            }
            ElMessage.success(msg);
            const user = await profile();
            const authInfo = await auth();
            this.$store.commit("USER_LOGIN", {
              userInfo: user.data,
            });
            this.$store.commit("SET_AUTH_INFO", {
              authInfo: authInfo.data,
            });
            VueEvent.emit("triggerIdentity", false);
            VueEvent.emit("identitySuccess");
          } else {
            ElMessage.warning("信息填写不完整，请仔细检查并重新提交");
            return false;
          }
        } catch (error) {
          ElMessage.error(error);
        }
      });
    },
  },
  async mounted() {
    this.action = import.meta.env.VITE_APP_BASE_URL + "/uploader/auth";
    const resQueue = await Promise.all([profile(), getDepartment(), auth()]);
    if (
      resQueue[2].data.auth_status == 1 ||
      resQueue[2].data.auth_status == 3
    ) {
      if (!resQueue[2].data.verify) {
        this.userData = resQueue[2].data.auth_data;
      } else {
        this.userData = resQueue[2].data.verify;
      }
    } else {
      this.userData = resQueue[2].data.auth_data;
    }
    this.reason = resQueue[2].data.verify?.reason;
    const id_card = this.userData.id_card;
    // if (this.userData.passport_id) {
    //   this.userData.passport_id = "";
    // }
    // if (this.userData.passport_photo) {
    //   this.userData.passport_photo = "";
    //   this.userData.passport_photo_face = "";
    // }
    delete this.userData.id_card;
    // 用户手机号
    this.userPhone = resQueue[0].data.phone;
    if (!resQueue[0].data.name || !id_card) {
      // VueEvent.emit("triggerIdentity");
      VueEvent.emit("triggerCertification", false);
      /*身份认证*/
      VueEvent.emit("triggerIdentity", false);
      this.loading = false;
      ElMessage.warning("请先进行实名认证");
      return false;
    }
    if (parseInt(resQueue[2].data.auth_status) === 2) {
      this.auditFailed = true;
    }
    //获取职业列表，2是医务人员职业列表，11是其他人员职业列表
    if (parseInt(this.userData.user_type) === 1) {
      this.getCommon(2);
    } else {
      this.getCommon(11);
    }
    this.departList = resQueue[1].data;
    // 医护人员
    if (parseInt(this.userData.user_type) === 1) {
      // if (this.isEdit === false) {
      this.doctorForm = this.userData;
      this.doctorForm.company_name = [0, 1].includes(this.userData.user_type)
        ? this.userData.hospital
        : this.userData.company_name;
      this.doctorForm.company_id = [0, 1].includes(this.userData.user_type)
        ? this.userData.hospital_id
        : this.userData.company_id;
      this.doctorForm.depart = [
        this.userData.depart_id,
        this.userData.depart_ids,
      ];
      this.doctorForm.depart_id = this.userData.depart_id;
      this.doctorForm.depart_ids = this.userData.depart_ids;
      //渲染初始化图片
      this.headImageUrl = this.userData.passport_photo_face;
      this.backImageUrl = this.userData.passport_photo;
      // 赋值一份
      this.doctorForm.passport_id = this.userData.passport_id;
      this.doctorForm.passport_photo = this.userData.passport_photo;
      this.doctorForm.passport_photo_face = this.userData.passport_photo_face;
      // }
    }
    // 其他人员
    if (parseInt(this.userData.user_type) === 2) {
      // if (this.isEdit === false) {
      this.staffForm = this.userData;
      // }
    }
    if (this.userData.profession_id) {
      // 根据职业id获取职称列表
      this.technicalFun(this.userData.profession_id);
      // 获取职务  9为医护人员职务，12为其他人员职务
      if (parseInt(this.userData.user_type) === 1) {
        this.getCommon(9);
      } else {
        this.getCommon(12);
      }
    }
    this.loading = false;
  },
};
</script>

<style lang="less" scoped>
.mask {
  background-color: rgb(0, 0, 0);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.identity {
  width: 560px;
  height: 800px;
  border-radius: 16px;
  box-sizing: border-box;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  background: #fff;
  z-index: 1111;
  box-shadow: 0px 11px 14px 2px rgba(32, 32, 32, 0.17);
  padding: 30px 0 0 0;

  .identity-close {
    font-size: 32px;
    position: absolute;
    right: 30px;
    top: 20px;
    color: #202020;
  }

  .cer-content {
    height: 100%;

    h3 {
      text-align: center;
      color: #111111;
      font-size: 20px;
      padding: 20px 0;
      margin: 0 50px;
      border-bottom: 1px solid #dddddd;
    }

    .from-content {
      overflow: auto;
      height: 90%;
      padding: 0 50px;

      .from-notice {
        text-align: center;
        color: #e57922;
        font-size: 12px;
        margin: 15px 0 10px;
      }

      .headForm {
        border-bottom: 1px dashed #eeeeee;
      }

      .doctorForm {
        margin-top: 20px;
      }

      .el-form {
        ::v-deep(.el-form-item__label) {
          color: #201f1f;
        }

        ::v-deep(.el-input--suffix) {
          width: 100%;
        }

        .id-head {
          color: #0398FE;
          font-size: 14px;
          margin: 20px 0;
          padding: 0 30px;
        }

        .prompt {
          line-height: 13px;
          margin-top: 8px;
          display: flex;

          img {
            width: 15px;
            height: 15px;
          }

          p {
            font-size: 12px;
            color: #999999;
          }
        }

        .upload-block {
          display: flex;
          width: 100%;
          justify-content: space-around;

          ::v-deep(.avatar-uploader) {
            width: 120px;
            height: 120px;
            border: 1px solid #ddd;
            border-radius: 10px;
            text-align: center;
            background: #f6f6f6;
            display: flex;
            align-items: center;
            justify-content: center;

            .up-con {
              margin-top: 10px;

              i {
                border: 2px solid #999;
                font-size: 20px;
                color: #999;
                font-weight: bold;
                border-radius: 50%;
                padding: 2px;
                position: relative;
                top: 10px;
              }

              div {
                font-size: 10px;
                color: #999;
              }
            }
          }

          ::v-deep(.el-upload--text) {
            max-width: 122px;
            max-height: 122px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .avatar {
            max-width: 122px;
            max-height: 122px;
          }
        }
      }

      .submit {
        text-align: center;
        margin-top: 50px;

        ::v-deep(.el-form-item__content) {
          margin-left: 0 !important;
        }

        .el-button {
          width: 200px;
          height: 44px;
          background: #ddd;
          border: none;
          border-radius: 22px;
        }

        .jump {
          margin-top: 20px;
          cursor: pointer;

          span {
            color: #0398FE;
          }
        }
      }

      .submit-button {
        .el-button {
          background: #0398FE;
        }
      }
    }
  }

  .auditFailed {
    width: 360px;
    height: 200px;
    background: #fff;
    box-shadow: 0px 4px 14px 2px rgba(32, 32, 32, 0.17);
    border-radius: 16px;
    position: absolute;
    padding: 40px 50px 20px;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 0);
    box-sizing: border-box;

    .close {
      display: inline-block;
      position: absolute;
      right: 20px;
      top: 15px;
      cursor: pointer;
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      div {
        color: #05C064;
        margin-left: 10px;
      }
    }

    p {
      color: #888888;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  ::v-deep(.el-form-item__label:before) {
    content: "*";
    color: #05C064;
    margin-right: 2px;
  }
}
</style>
