import request from '../utils/request'

// 金币明细
export function transactions(params) {
    return request.get(`/money/transactions`, {params})
}

// 金币提现
export function withdrawals(params) {
    return request.post(`/money/withdrawals`, params)
}

// 金币充值
export function chargeGold(params) {
    return request.post(`/money/recharge`, params)
}

// 金币充值状态查询
export function validateChargeStatus(params) {
    return request.post(`/money/charge`, params)
}