<template>
  <transition name="slide-fade">
    <div class="wrapper" v-if="visible" @click="updateVisible">
      <div class="login" @click.stop>
        <i class="el-icon-close login-close" @click="updateVisible"></i>
        <div class="login-content" v-if="loginVisible">
          <div class="login-head">
            <div :class="smsLogin ? 'now' : ''" @click="smsLogin = true">
              验证码登录
            </div>
            <div :class="!smsLogin ? 'now' : ''" @click="smsLogin = false">
              密码登录
            </div>
          </div>
          <div class="login-from">
            <div class="from-item">
              <p class="item-title">手机号码</p>
              <div class="item-input">
                <input
                  type="text"
                  v-model="phone"
                  placeholder="请输入手机号码"
                  maxlength="11"
                />
              </div>
            </div>
            <div v-show="smsLogin">
              <div class="from-item">
                <p class="item-title">验证码</p>
                <div
                  :class="
                    validCode
                      ? 'item-input item-code codeInput'
                      : 'item-input item-code'
                  "
                >
                  <input
                    type="number"
                    v-model="code"
                    placeholder="请输入验证码"
                    maxlength="4"
                    @input="codeFun"
                  />
                  <verify-code
                    :mobile="phone"
                    @phone-error="$message.error('手机号格式错误')"
                  ></verify-code>
                </div>
              </div>
            </div>
            <div v-show="!smsLogin">
              <div class="from-item">
                <p class="item-title">密码</p>
                <div
                  :class="
                    validPassword
                      ? 'item-input item-code'
                      : 'item-input item-code pwdType'
                  "
                >
                  <input
                    type="password"
                    v-model="password"
                    placeholder="请输入密码"
                    maxlength="16"
                    @blur="inputPwd"
                  />
                  <div class="forget-pwd" @click="resetFun">忘记密码</div>
                </div>
                <p class="pwdFalse" v-if="!validPassword">
                  {{ passwordErrorContent }}
                </p>
              </div>
            </div>
          </div>
          <div class="from-rule">
            <div class="item-check" @click="readAgreement = !readAgreement">
              <i class="el-icon-check" v-show="readAgreement"></i>
            </div>
            <p>
              我已阅读并同意<span
                @click="$emit('showAgreement', 1)"
                style="cursor: pointer"
                >《用户协议》</span
              >和<span
                style="cursor: pointer"
                @click="$emit('showAgreement', 3)"
                >《隐私协议》</span
              >
            </p>
          </div>
          <div class="login-btn">
            <span
              @click="loginAction"
              :class="{ 'not-span': (phone && code) || (phone && password) }"
              style="cursor: pointer"
              >登录</span
            >
          </div>
          <p class="login-notice">未注册用户验证后即自动注册</p>
        </div>
        <div v-if="resetVisible">
          <div class="back" @click="backLogin">
            <svg
              t="1638238507266"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2268"
              width="26"
              height="26"
            >
              <path
                d="M595.3 945.9c-13.9 0-27.3-5.5-37.2-15.4l-383.5-382c-20.5-20.5-20.5-53.6 0-74.1L558.1 92.3c20.6-20.2 53.7-20 74.1 0.3 20.4 20.4 20.5 53.3 0.3 73.8l-346.2 345 346.3 345.1c15 15 19.5 37.5 11.4 57.1-8.3 19.5-27.4 32.3-48.7 32.3z"
                p-id="2269"
              ></path>
            </svg>
          </div>
          <reset-pwd />
        </div>
      </div>
    </div>
  </transition>
  <transition name="slide-fade">
    <complete-information
      v-if="formVisible"
      :meeting-form="formInfo"
      @close-dialog="formVisible = false"
    />
  </transition>
  <!--  <index-popup-publicize v-if="show" ad-id="74" />-->
</template>

<script>
import VerifyCode from "./verify-code.vue";
import ResetPwd from "./reset-pwd.vue";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { codeLogin, pwdLogin } from "../../api/auth_api";
import { auth, profile } from "../../api/personal_api";
import { useStore } from "vuex";
import VueEvent from "../../utils/global_event";
import Cookies from "js-cookie";
import { useRouter } from "vue-router";
import IndexPopupPublicize from "../../components/publicize/index-popup-publicize.vue";
import CompleteInformation from "../../components/auth/complete-information.vue";
import LoggerUtils from "../../utils/LoggerUtils";
import useRegDialog from "../../hooks/common/useRegDialog";

export default {
  name: "Login",
  components: {
    VerifyCode,
    ResetPwd,
    // IndexPopupPublicize,
    CompleteInformation,
  },
  emits: [
    "update:visible",
    "showAgreement",
    "needCertification",
    "needIdentity",
  ],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // load vuex
    const store = useStore();
    const smsLogin = ref(true);
    const phone = ref("");
    const code = ref("");
    const password = ref("");
    const validPassword = ref(true);
    const validCode = ref(true);
    const readAgreement = ref(false);
    const resetVisible = ref(false);
    const loginVisible = ref(true);
    const router = useRouter();
    /**  hooks函数 start */
    // 完善信息弹窗公共函数
    const { getRegListCom, formVisible, formInfo } = useRegDialog();
    /** hooks函数 end */
    const show = ref(false);
    const passwordErrorContent = ref("密码输入不正确，请重新输入");
    // 登录行为
    const loginAction = async () => {
      if (!readAgreement.value) {
        ElMessage.warning("请阅读并同意用户协议后继续");
        return;
      }
      if (!/^1[3-9]\d{9}$/.test(phone.value)) {
        ElMessage.error("手机号错误");
        return;
      }
      // 如果是验证码登录但是没有输入验证码
      if (smsLogin.value && code.value.length !== 4) {
        ElMessage.warning("请输入正确验证码");
        return;
      }
      // 如果是密码登录但是没有输入密码
      if (!smsLogin.value && password.value.trim().length === 0) {
        ElMessage.error("请输入密码");
        return;
      }

      try {
        const { data, errorCode, msg } = smsLogin.value
          ? await codeLogin({
              phone: phone.value,
              verify_code: code.value,
            })
          : await pwdLogin({
              phone: phone.value,
              password: password.value,
            });
        // 关闭dialog
        context.emit("update:visible", false);
        // 保存首次登录
        // localStorage.FirstLogin = "1";
        const loginCookieArr = [
          "index",
          "users_index",
          "live",
          "integral_index",
        ];
        if (localStorage.getItem("FirstLogin") !== "0") {
          for (const item in loginCookieArr) {
            localStorage[`JY+${loginCookieArr[item]}`] = "1";
          }
        }

        show.value = true;
        // 保存token信息
        localStorage.access_token = data.access_token;
        localStorage._socket_ = data.socket_token;
        store.commit("SET_TOKEN", {
          token: data.access_token,
        });
        await store.dispatch("fetchSocketToken");
        // fetch userInfo
        const user = await profile();
        store.commit("USER_LOGIN", {
          userInfo: user.data,
        });
        // 检查权限信息
        const authInfo = await auth();
        store.commit("SET_AUTH_INFO", {
          authInfo: authInfo.data,
        });
        const url = Cookies.get("redirect_url");

        if (url) {
          router.push(url).then((res) => {
            Cookies.remove("redirect_url");
          });
        }
        await getRegListCom();

        // 如果需要跳过认证
        if (store.getters.isSkipAuth) {
          return;
        }
        /**
         * 登录之后刷新页面，
         * 展示当前页面的广告
         */
        location.reload();
        // 需要身份认证
        // if (authInfo.data.auth_data.id_card === '') {
        //   context.emit("needCertification")
        //   return
        // }
        // 需要实名认证
        // if (authInfo.data.auth_data.id_card === '') {
        //   context.emit("needIdentity")
        // }
      } catch (e) {
        validPassword.value = false;
        passwordErrorContent.value = e.toString();
        ElMessage.error(e.toString());
      }
    };
    const inputPwd = () => {
      validPassword.value = true;
    };
    const updateVisible = () => {
      phone.value = null;
      code.value = null;
      password.value = null;
      context.emit("update:visible", false);
      loginVisible.value = true;
      resetVisible.value = false;
    };
    const resetFun = () => {
      loginVisible.value = false;
      resetVisible.value = true;
    };
    const backLogin = () => {
      resetVisible.value = false;
      loginVisible.value = true;
    };
    watch(
      () => code.value,
      (nVal, oVal) => {
        if (nVal > 4) {
          code.value = nVal.toString().slice(0, 4);
        }
      }
    );
    onMounted(() => {
      VueEvent.on("backLogin", () => {
        backLogin();
      });
    });

    onUnmounted(() => {
      VueEvent.off("backLogin");
    });
    return {
      smsLogin,
      phone,
      code,
      password,
      validCode,
      validPassword,
      readAgreement,
      loginAction,
      updateVisible,
      loginVisible,
      resetVisible,
      resetFun,
      backLogin,
      passwordErrorContent,
      inputPwd,
      show,
      getRegListCom,
      formVisible,
      formInfo,
    };
  },
  methods: {
    codeFun() {
      if (this.code.length > 0) {
        this.validCode = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(10px);
}

.wrapper {
  background-color: rgba(0, 0, 0, 0.22);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .login {
    width: 480px;
    height: 600px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 11px 14px 2px rgba(32, 32, 32, 0.17);

    box-sizing: border-box;
    position: relative;

    .login-close {
      font-size: 32px;
      position: absolute;
      right: 30px;
      top: 20px;
      color: #202020;
      cursor: pointer;
    }

    .login-content {
      padding: 80px 80px 20px;
      .login-head {
        display: flex;
        align-items: baseline;

        div {
          width: 50%;
          border-bottom: 1px solid #dddddd;
          text-align: center;
          padding: 17px 25px;
          font-size: 16px;
          color: #bababa;
          cursor: pointer;
        }

        .now {
          color: #0398FE;
          font-size: 20px;
          border-bottom: 2px solid #0398FE;
        }
      }

      .login-from {
        margin-top: 60px;

        .from-item {
          margin-top: 30px;

          .item-title {
            font-size: 18px;
            color: #545454;
            margin-bottom: 20px;
          }

          .item-input {
            border-bottom: 1px solid #ddd;
            padding: 8px 0 15px;

            input {
              height: 100%;
              border: none;
              color: #111111;
              font-size: 18px;
              outline: none;
            }

            input::placeholder {
              color: #bababa;
            }
          }

          .item-code {
            display: flex;
            align-items: baseline;
            color: #0398FE;
            font-size: 16px;
            justify-content: space-between;

            .forget-pwd {
              color: #dfdfdd;
              cursor: pointer;
            }
          }

          .codeInput {
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid #0398FE;

            input {
              color: #0398FE;
              width: 100%;
            }
          }

          .pwdType {
            border-bottom: 1px solid #05C064;
          }

          .pwdFalse {
            color: red;
            font-size: 14px;
          }
        }
      }

      .from-rule {
        margin-top: 5px;
        display: flex;
        align-items: center;

        div {
          width: 14px;
          height: 14px;
          background: #f6f6f6;
          border: 1px solid #dddddd;
          border-radius: 50%;
          margin-right: 10px;
          margin-top: 2px;
          text-align: center;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 10px;
            font-weight: bold;
          }
        }

        .item-check {
          border: 1px solid #0398FE;
          color: #0398FE;
        }

        p {
          color: #999;
          font-size: 14px;

          span {
            font-size: 14px;
          }
        }
      }

      .login-btn {
        text-align: center;
        margin-top: 30px;

        span {
          display: inline-block;
          width: 200px;
          height: 44px;
          background: #89b8f2;
          box-shadow: 0px 7px 19px 1px rgba(84, 123, 213, 0.35);
          border-radius: 22px;
          color: #fff;
          line-height: 44px;
        }
        .not-span {
          background: #0398FE;
        }
      }

      .login-notice {
        text-align: center;
        font-size: 14px;
        color: #bababa;
        margin-top: 30px;
      }
    }
  }

  .back {
    display: inline-block;
    position: absolute;
    top: 25px;
    left: 30px;
    cursor: pointer;
  }
}
</style>
