import Layout from "@components/layout/Layout.vue";
// 病例库页面
export default {
  path: "/case",
  component: Layout,
  meta: {
    transition: "slide",
  },
  children: [
    {
      path: "index",
      component: () => import("@pages/case/index.vue"),
    },
    {
      path: "explain",
      component: () => import("@pages/case/explain.vue"),
    },
    {
      path: "communication",
      component: () => import("@pages/case/communication.vue"),
    },
  ],
};
