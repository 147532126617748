import {routes} from "./routes";
import {createRouter, createWebHashHistory} from "vue-router";
import Scheduling from "../utils/scheduling";

const routerHistory = createWebHashHistory()
const scheduling = new Scheduling()
import store from "../store";
import {getPageMap} from "../api/common_api";
import Cookies from "js-cookie";
import VueEvent from "../utils/global_event";

const freeRoutes = ['/index', '/spa/index', '/courses/index', '/live/index', '/live/intro-list', '/live/live-list', '/live/playback-list']

// 创建路由
const router = createRouter({
    history: routerHistory,
    routes,
})

const pageDurationRec = (pageInfo) => {
    const logger = store.getters.getLogger
    if (logger) {
        logger.pageDurationEvent(
            pageInfo.path,
            pageInfo.duration,
            JSON.stringify(pageInfo.query),
            pageInfo.recordUid
        );
    }
};
// 页面跳转时间
const pageNavigateRec = (to, from) => {
    const logger = store.getters.getLogger
    if (logger) {
        logger.pageNavigateEvent(
            from.path,
            to.path,
            JSON.stringify(from.query),
            JSON.stringify(to.query)
        );
    }
};
// 处理移动端跳转
const handleMobile = async (to, next) => {
    if (isMobile()) {
        const prefix = "https://m.jiyuanyixue.com/#"
        const {data} = await getPageMap()
        const routes = data
        const {path, fullPath} = to
        const mobileRoute = routes.filter(route => route.pc_route === path)
        const params = fullPath.split("?")[1]
        if (mobileRoute.length === 0) {
            window.location.href = prefix + '/pages/tabs/index'
        } else {
            if (params) {
                window.location.href = prefix + mobileRoute[0].m_route + '?' + params
            } else {
                window.location.href = prefix + mobileRoute[0].m_route
            }
        }
        next(false)
    }
}
router.beforeEach((to, from, next) => {
    handleMobile(to, next).then(res => {
    })
    if (!freeRoutes.includes(to.path) && !localStorage.access_token) {
        // const url = Cookies.get("redirect_url")
        // if (!url) {
        Cookies.set("redirect_url", to.fullPath)
        // }
        VueEvent.emit("showLogin")
        next(false)
        return
    }
    scheduling.stop()
    // 页面跳转日志
    pageNavigateRec(to, from);
    next()
})

router.afterEach((to, from) => {
    window.scroll(0, 0)
    // 页面停留日志
    scheduling.bindFn(pageDurationRec, to);
    scheduling.begin();
})
export default router